import { authHeader } from "../../../../auth";
export default {
    data() {
        return {
            setURL: this.$serverURL + this.$api.location.pageCmsURL,
            pageCMSData: [],
            imagePath: this.$imageURL + 'location-cms/',
            previewImage: ''
        }
    },
    async mounted() {
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
    },
    methods: {
        uploadImage(event) {
            this.pageCMSData.backgroundImage = event.target.files[0];
            let input = this.$refs.addImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        async update() {
            try {
                if (!this.pageCMSData.backgroundImage) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter Image!"
                    });
                }
                else if (!this.pageCMSData.locationPageTitle) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter location page title!!!"
                    });
                } else if (!this.pageCMSData.locationDetailsPageTitle) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter location details page!"
                    });
                } else if (!this.pageCMSData.enqueryTitle) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter enquery title!"
                    });
                } else if (!this.pageCMSData.buttonText) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter button text!"
                    });
                }
                else {
                    let formData = new FormData();
                    formData.append('backgroundImage', this.pageCMSData.backgroundImage);
                    formData.append('locationPageTitle', this.pageCMSData.locationPageTitle);
                    formData.append('locationDetailsPageTitle', this.pageCMSData.locationDetailsPageTitle);
                    formData.append('hearAboutUsOptions', this.pageCMSData.hearAboutUsOptions);
                    formData.append('enqueryTitle', this.pageCMSData.enqueryTitle);
                    formData.append('buttonText', this.pageCMSData.buttonText);
                    let config = {
                        method: 'POST',
                        url: this.setURL,
                        data: formData,
                        headers: {
                            "Authorization": authHeader(),
                            "Content-Type": "multipart/form-data",
                        }
                    };
                    await this.createUpdateCMS(config);
                    await this.getCmsData(this.setURL);

                }
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! " + error
                });
            }
        }
    }
}