<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">About Page CMS</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">About Page CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">About Page CMS</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="border border-3 p-4 rounded">
                                    <div class="mb-3">
                                        <label class="form-label">Page Title</label>
                                        <input type="text" v-model="pageCMSData.pageTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter Page Title">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Page Subtitle</label>
                                        <input type="text" v-model="pageCMSData.pageSubTitle" class="form-control" id="inputPageHeaderPageName" placeholder="Enter Page Subtitle">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section One Title</label>
                                        <input type="text" v-model="pageCMSData.sectionOneTitle" class="form-control" id="inputPageHeaderPageName" placeholder="Enter Section One Title">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section One Subtitle</label>
                                        <input type="text" v-model="pageCMSData.sectionOneSubTitle" class="form-control" id="inputPageHeaderPageName" placeholder="Enter Section One Subtitle">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section Two Title</label>
                                        <input type="text" v-model="pageCMSData.sectionTwoTitle" class="form-control" id="inputPageHeaderPageName" placeholder="Enter Section Two Title">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section Two Subtitle</label>
                                        <input type="text" v-model="pageCMSData.sectionTwoSubTitle" class="form-control" id="inputPageHeaderPageName" placeholder="Enter Section Two Subtitle">
                                    </div>
                                    <div class="d-grid">
                                        <button v-on:click="updateCms()" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/about-page-cms.js"></script>