import { authHeader } from "../../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            setURL: '',
            pageCMSData: [],
            brandBackgroundImage: "",
            previewImage: "",
        }
    },
    async mounted() {
        this.setURL = this.$serverURL + this.$api.cms.homePageCMS;
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
    },
    methods: {
        uploadFeaturedImage: function (event) {
            this.brandBackgroundImage = event.target.files[0];
            let input = this.$refs.backgroundImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        async updateCms() {
            try {

                let formData = new FormData();
                formData.append('topSellingBackgroundText', this.pageCMSData.topSellingBackgroundText);
                formData.append('topSellingTitle', this.pageCMSData.topSellingTitle);
                formData.append('topsellingSubtitle', this.pageCMSData.topsellingSubtitle);
                formData.append('featuredProductBackgroundText', this.pageCMSData.featuredProductBackgroundText);
                formData.append('featuredProductTitle', this.pageCMSData.featuredProductTitle);
                formData.append('featuredProductSubtitle', this.pageCMSData.featuredProductSubtitle);

                formData.append('customerReviewBackgroundText', this.pageCMSData.customerReviewBackgroundText);
                formData.append('customerReviewTitle', this.pageCMSData.customerReviewTitle);
                formData.append('customerReviewSubtitle', this.pageCMSData.customerReviewSubtitle);

                formData.append('instaPostBackgroundText', this.pageCMSData.instaPostBackgroundText);
                formData.append('instaPostTitle', this.pageCMSData.instaPostTitle);
                formData.append('instaPostSubtitle', this.pageCMSData.instaPostSubtitle);

                formData.append('homeBlogBackgroundText', this.pageCMSData.homeBlogBackgroundText);
                formData.append('homeBlogTitle', this.pageCMSData.homeBlogTitle);
                formData.append('homeBlogSubTitle', this.pageCMSData.homeBlogSubTitle);
                formData.append('buttonText', this.pageCMSData.buttonText);
                formData.append('brandBackgroundImage', this.brandBackgroundImage ? this.brandBackgroundImage : this.pageCMSData.brandBackgroundImage);

                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.cms.homePageCMS,
                    data: formData,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                await this.createUpdateCMS(config);
                this.brandBackgroundImage = "";
                this.$refs.backgroundImage.value = "";
                this.previewImage = "";
                await this.getCmsData(this.setURL);
                if (this.pageCMS) {
                    this.pageCMSData = this.pageCMS;
                }
                else {
                    this.pageCMSData = [];
                }
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! " + error
                });
            }
        }
    }
}