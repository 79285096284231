<template>
  <div class="sidebar-wrapper" data-simplebar="true">
    <div class="sidebar-header">
      <div>
        <img
          src="../../../../public/core/assets/images/logo-icon.png"
          class="logo-icon"
          alt="logo icon"
        />
      </div>
      <div>
        <h4 class="logo-text">HGS</h4>
      </div>
      <div class="toggle-icon ms-auto"><i class="bx bx-arrow-to-left"></i></div>
    </div>
    <ul class="metismenu" id="menu">
      <li>
        <a href="javascript:void(0)" @click="$router.push('/home')">
          <div class="parent-icon"><i class="bx bx-home-circle"></i></div>
          <div class="menu-title">Dashboard</div>
        </a>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="fadeIn animated bx bx-user"></i>
          </div>
          <div class="menu-title">User</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/admins')"
              ><i class="bx bx-right-arrow-alt"></i>Admins</a
            >
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/customers')"
              ><i class="bx bx-right-arrow-alt"></i>Customers</a
            >
          </li>
        </ul>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon"><i class="lni lni-producthunt"></i></div>
          <div class="menu-title">Product</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/product-list')"
              ><i class="bx bx-right-arrow-alt"></i>Products</a
            >
          </li>
          <li>
            <a
              href="javascript:void(0)"
              @click="$router.push('/product-reorder')"
              ><i class="bx bx-right-arrow-alt"></i>Product Reorder</a
            >
          </li>
          <li>
            <a
              href="javascript:void(0)"
              @click="$router.push('/stock-in-product')"
              ><i class="bx bx-right-arrow-alt"></i>Stock In</a
            >
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/stock-list')"
              ><i class="bx bx-right-arrow-alt"></i>Stock In Report</a
            >
          </li>

          <li>
            <a href="javascript:void(0)" @click="$router.push('/product-brand')"
              ><i class="bx bx-right-arrow-alt"></i>Brand</a
            >
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="fa-solid fa-cubes"></i>
              </div>
              <div class="menu-title">Product Category</div>
            </a>
            <ul>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="$router.push('/parent-category')"
                  ><i class="bx bx-right-arrow-alt"></i>Category</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="$router.push('/sub-category')"
                  ><i class="bx bx-right-arrow-alt"></i>Sub Category</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="$router.push('/child-category')"
                  ><i class="bx bx-right-arrow-alt"></i>Child Category</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="$router.push('/featured-categories')"
                  ><i class="bx bx-right-arrow-alt"></i>Featured Categories</a
                >
              </li>
            </ul>
          </li>
          <!-- <li>
            <a href="javascript:void(0)" @click="$router.push('/product-color')"
              ><i class="bx bx-right-arrow-alt"></i>Product Color</a
            >
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/product-size')"
              ><i class="bx bx-right-arrow-alt"></i>Product Size</a
            >
          </li> -->
          <li>
            <a
              href="javascript:void(0)"
              @click="$router.push('/product-discount')"
              ><i class="bx bx-right-arrow-alt"></i>Discount</a
            >
          </li>
          <li>
            <a
              href="javascript:void(0)"
              @click="$router.push('/product-review')"
              ><i class="bx bx-right-arrow-alt"></i>Product Review</a
            >
          </li>
        </ul>
      </li>
      <li>
        <a class="has-arrow" href="javascript:void(0)"
          ><div class="parent-icon">
            <i class="fadeIn animated bx bx-cart"></i>
          </div>
          <div class="menu-title">Order</div></a
        >
        <ul>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/create-order')"
              ><i class="bx bx-right-arrow-alt"></i>Create Order</a
            >
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/orders')"
              ><i class="bx bx-right-arrow-alt"></i>Order List</a
            >
          </li>
        </ul>
      </li>
      <li>
        <a class="has-arrow" href="javascript:void(0)"
          ><div class="parent-icon">
            <i class="fa-brands fa-blogger"></i>
          </div>
          <div class="menu-title">Blog</div></a
        >
        <ul>
          <li>
            <a
              href="javascript:void(0);"
              @click="$router.push('/blog-category')"
              ><i class="bx bx-right-arrow-alt"></i>Blog Category</a
            >
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/blog-tags')"
              ><i class="bx bx-right-arrow-alt"></i>Blog Tags</a
            >
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/blog-post')"
              ><i class="bx bx-right-arrow-alt"></i>Blog Post</a
            >
          </li>
          <li>
            <a
              href="javascript:void(0);"
              @click="$router.push('/blog-comments')"
              ><i class="bx bx-right-arrow-alt"></i>Blog Comments</a
            >
          </li>
        </ul>
      </li>
      <li>
        <a class="has-arrow" href="javascript:void(0)"
          ><div class="parent-icon">
            <i class="fadeIn animated bx bx-message-detail"></i>
          </div>
          <div class="menu-title">Contact</div></a
        >
        <ul>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/contact')"
              ><i class="bx bx-right-arrow-alt"></i>Contact Content</a
            >
          </li>
          <li>
            <a
              href="javascript:void(0);"
              @click="$router.push('/contact-message')"
              ><i class="bx bx-right-arrow-alt"></i>Contact Messages</a
            >
          </li>
        </ul>
      </li>
      <li>
        <a class="has-arrow" href="javascript:void(0)"
          ><div class="parent-icon">
            <i class="bx bx-current-location"></i>
          </div>
          <div class="menu-title">Location</div></a
        >
        <ul>
          <li>
            <a
              href="javascript:void(0);"
              @click="$router.push('/location-list')"
              ><i class="bx bx-right-arrow-alt"></i>Location List</a
            >
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/enquiry-list')"
              ><i class="bx bx-right-arrow-alt"></i>Enquiry List</a
            >
          </li>
        </ul>
      </li>
      <li>
        <a href="javascript:void(0)" @click="$router.push('/franchise')">
          <div class="parent-icon">
            <i class="fadeIn animated bx bx-store-alt"></i>
          </div>
          <div class="menu-title">Franchise</div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="$router.push('/team-member')">
          <div class="parent-icon">
            <i class="fa-solid fa-people-group"></i>
          </div>
          <div class="menu-title">Team Member</div>
        </a>
      </li>
      <!-- <li>
        <a href="javascript:void(0)" @click="$router.push('/instagram-feed')">
          <div class="parent-icon">
            <i class="fa-brands fa-instagram"></i>
          </div>
          <div class="menu-title">Instagram Feed</div>
        </a>
      </li> -->
      <li>
        <a href="javascript:void(0)" @click="$router.push('/coupon')">
          <div class="parent-icon">
            <i class="fa-solid fa-tag"></i>
          </div>
          <div class="menu-title">Coupons</div>
        </a>
      </li>

      <!-- <li>
        <a href="javascript:void(0)" @click="$router.push('/payment-method')">
          <div class="parent-icon">
            <i class="fadeIn animated bx bx-money"></i>
          </div>
          <div class="menu-title">Payment Method</div>
        </a>
      </li> -->

      <!-- <li>
        <a href="javascript:void(0)" @click="$router.push('/slider')">
          <div class="parent-icon">
            <i class="fadeIn animated bx bx-carousel"></i>
          </div>
          <div class="menu-title">Slider</div>
        </a>
      </li> -->
      <li>
        <a href="javascript:void(0)" @click="$router.push('/subscriber')">
          <div class="parent-icon">
            <i class="fadeIn animated bx bx-message-square"></i>
          </div>
          <div class="menu-title">Subscriber</div>
        </a>
      </li>

      <li>
        <a class="has-arrow" href="javascript:void(0)"
          ><div class="parent-icon">
            <i class="fadeIn animated bx bx-info-circle"></i>
          </div>
          <div class="menu-title">About Us</div></a
        >
        <ul>
          <li>
            <a
              href="javascript:void(0);"
              @click="$router.push('/about-content')"
              ><i class="bx bx-right-arrow-alt"></i>About Content</a
            >
          </li>
          <li>
            <a
              href="javascript:void(0);"
              @click="$router.push('/about-services')"
              ><i class="bx bx-right-arrow-alt"></i>About Services</a
            >
          </li>
        </ul>
      </li>
      <li>
        <a class="has-arrow" href="javascript:void(0)"
          ><div class="parent-icon">
            <i class="fa-solid fa-money-bill"></i>
          </div>
          <div class="menu-title">Payment</div></a
        >
        <ul>
          <li>
            <a
              href="javascript:void(0);"
              @click="$router.push('/payment-method')"
              ><i class="bx bx-right-arrow-alt"></i>Payment Methods</a
            >
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/payment-list')"
              ><i class="bx bx-right-arrow-alt"></i>Payment List</a
            >
          </li>
          <li>
            <a
              href="javascript:void(0);"
              @click="$router.push('/payment-settings')"
              ><i class="bx bx-right-arrow-alt"></i>Payment Settings</a
            >
          </li>
        </ul>
      </li>
      <li>
        <a class="has-arrow" href="javascript:void(0)">
          <div class="parent-icon"><i class="fa-solid fa-truck"></i></div>
          <div class="menu-title">Shipping Settings</div>
        </a>
        <ul>
          <li>
            <a
              href="javascript:void(0);"
              @click="$router.push('/fedex-settings')"
              ><i class="bx bx-right-arrow-alt"></i>Fedex Settings</a
            >
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/ups-settings')"
              ><i class="bx bx-right-arrow-alt"></i>Ups Settings</a
            >
          </li>
        </ul>
      </li>
      <li>
        <a class="has-arrow" href="javascript:void(0)">
          <div class="parent-icon"><i class="lni lni-cog"></i></div>
          <div class="menu-title">CMS Settings</div>
        </a>
        <ul>
          <li>
            <a class="has-arrow" href="javascript:void(0)"
              ><i class="lni lni-producthunt"></i>Product</a
            >
            <ul>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/product-cms')"
                  ><i class="bx bx-right-arrow-alt"></i>Product CMS</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/product-details-cms')"
                  ><i class="bx bx-right-arrow-alt"></i>Product Details CMS</a
                >
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)"
              ><i class="fa-brands fa-blogger"></i>Blog</a
            >
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/blog-cms')"
                  ><i class="bx bx-right-arrow-alt"></i>Blog CMS</a
                >
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)"
              ><i class="fadeIn animated bx bx-store-alt"></i>Franchise</a
            >
            <ul>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/franchise-cms')"
                  ><i class="bx bx-right-arrow-alt"></i>Franchise CMS</a
                >
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)"
              ><i class="fa-solid fa-bars-progress"></i>Header Footer</a
            >
            <ul>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/header-cms')"
                  ><i class="bx bx-right-arrow-alt"></i>Header CMS</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/footer-cms')"
                  ><i class="bx bx-right-arrow-alt"></i>Footer CMS</a
                >
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)"
              ><i class="lni lni-home"></i>Home</a
            >
            <ul>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/home-page-cms')"
                  ><i class="bx bx-right-arrow-alt"></i>Home Page CMS</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/slider')"
                  ><i class="bx bx-right-arrow-alt"></i>Slider Settings</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/brands')"
                  ><i class="bx bx-right-arrow-alt"></i>Brands</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/review-slider')"
                  ><i class="bx bx-right-arrow-alt"></i>Review Slider</a
                >
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)"
              ><i class="lni lni-link"></i>Social Links</a
            >
            <ul>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/social-links')"
                  ><i class="bx bx-right-arrow-alt"></i>Social Links</a
                >
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)"
              ><i class="fadeIn animated bx bx-message-detail"></i>Contact</a
            >
            <ul>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/contact-cms')"
                  ><i class="bx bx-right-arrow-alt"></i>Contact Page CMS</a
                >
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)"
              ><i class="fa-solid fa-location-pin"></i>Location</a
            >
            <ul>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/location-cms')"
                  ><i class="bx bx-right-arrow-alt"></i>Location CMS</a
                >
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)"
              ><i class="lni lni-timer"></i>Track Order</a
            >
            <ul>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/track-order-cms')"
                  ><i class="bx bx-right-arrow-alt"></i>Track Order CMS</a
                >
              </li>
            </ul>
          </li>

          <li>
            <a class="has-arrow" href="javascript:void(0)"
              ><i class="fa-solid fa-headset"></i>Help</a
            >
            <ul>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/help-page-cms')"
                  ><i class="bx bx-right-arrow-alt"></i>Help Page CMS</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="$router.push('/help-content')"
                >
                  <div class="parent-icon">
                    <i class="bx bx-right-arrow-alt"></i>
                  </div>
                  <div class="menu-title">Help Us Content</div>
                </a>
              </li>
            </ul>
          </li>

          <li>
            <a class="has-arrow" href="javascript:void(0)"
              ><i class="fadeIn animated bx bx-info-circle"></i>About Us</a
            >
            <ul>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/about-page-cms')"
                  ><i class="bx bx-right-arrow-alt"></i>About Page CMS</a
                >
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)"
              ><i class="lni lni-popup"></i>Pop Up Modal</a
            >
            <ul>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/ad-pop-up-cms')"
                  ><i class="bx bx-right-arrow-alt"></i>Ad Pop Up Modal CMS</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/newsletter-cms')"
                  ><i class="bx bx-right-arrow-alt"></i>Newsletter Modal CMS</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/pop-up-settings')"
                  ><i class="bx bx-right-arrow-alt"></i>Pop Up Settings</a
                >
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)"
              ><i class="fadeIn animated bx bx-check-shield"></i>Policies</a
            >
            <ul>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/terms&condition')"
                  ><i class="bx bx-right-arrow-alt"></i>Terms&Condition</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/privacy-policy')"
                  ><i class="bx bx-right-arrow-alt"></i>Privacy Policy</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/return-policy')"
                  ><i class="bx bx-right-arrow-alt"></i>Return Policy</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/cancellation-policy')"
                  ><i class="bx bx-right-arrow-alt"></i>Cancellation Policy</a
                >
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>