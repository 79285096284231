import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    name: "Coupon",
    data() {
        return {
            editor: ClassicEditor,
            setURL: this.$serverURL + this.$api.coupon.couponAPI,
            singleAPI: this.$serverURL + this.$api.coupon.singleCouponAPI,
            singleCoupon: {},
            couponName: '',
            couponCode: '',
            expireDate: '',
            discount: '',
            couponTypes: ['percent', 'fixed'],
            couponType: '',
            currentExpireDate: ''
        }
    },
    async created() {
        document.title = "HGS - Coupon";
        await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        addCoupon: async function () {
           
            if (!this.couponName || !this.expireDate || !this.couponType || !this.discount) {
               
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter Coupon name, Coupon expireDate, Coupon Type, discount amount!"
                });
            }
            else {
                let data = {
                    couponName: this.couponName,
                    expireDate: this.expireDate,
                    couponType: this.couponType,
                    discount: this.discount,
                };
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.postDataToBackend(config);
                this.couponName = '';
                this.expireDate = '';
                this.couponType = '';
                this.discount = '';
                await this.getDataList(this.setURL);
            }
        },
        editCoupon: async function (data) {
            this.singleCoupon = data;
            this.currentExpireDate = this.$filters.formatUpdateDate(data.expireDate).toString();
        },
        updateCoupon: async function () {
           
            if (!this.singleCoupon.couponName || !this.singleCoupon.expireDate || !this.singleCoupon.couponType || !this.singleCoupon.discount) {
               
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter Coupon name, Coupon expire Date, Coupon Type, discount amount!"
                });
            }
            else {
                let data = {
                    couponName: this.singleCoupon.couponName,
                    expireDate: this.singleCoupon.expireDate,
                    couponType: this.singleCoupon.couponType,
                    discount: this.singleCoupon.discount,
                };
                let config = {
                    method: "PATCH",
                    url: this.singleAPI + this.singleCoupon.id,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.updateDataToBackend(config);
                this.singleCoupon = {};
                this.currentExpireDate = '';
                await this.getDataList(this.setURL);
            }
        },
        deleteCoupon: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.singleAPI + id,
                            headers: {
                                "Authorization": authHeader(),
                            }
                        }
                        await this.deleteDataToBackend(config);
                        await this.getDataList(this.setURL);

                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })

        }
    }
}