<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Home</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard"
                  ><i class="bx bx-home-alt"></i
                ></router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Home Page CMS
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Home Page CMS</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-8">
                <div class="border border-3 p-4 rounded">
                  <div class="mb-3">
                    <label class="form-label"
                      >Top Selling Background Text</label
                    >
                    <input
                      type="text"
                      v-model="pageCMSData.topSellingBackgroundText"
                      class="form-control"
                      id="inputPageHeaderTitle"
                      placeholder="Enter Top Selling Background Text"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Top Seling Title</label>
                    <input
                      type="text"
                      v-model="pageCMSData.topSellingTitle"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="Enter Top Seling Title"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Top Seling Subtitle</label>
                    <input
                      type="text"
                      v-model="pageCMSData.topsellingSubtitle"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="Enter Top Seling Subtitle"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label"
                      >Featured Product Background Text</label
                    >
                    <input
                      type="text"
                      v-model="pageCMSData.featuredProductBackgroundText"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="Enter Featured Product Background Text"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Featured Product Title</label>
                    <input
                      type="text"
                      v-model="pageCMSData.featuredProductTitle"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="Enter Featured Product Title"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Featured Product Subtitle</label>
                    <input
                      type="text"
                      v-model="pageCMSData.featuredProductSubtitle"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="Enter Featured Product Subtitle"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label"
                      >Customer Review Background Text</label
                    >
                    <input
                      type="text"
                      v-model="pageCMSData.customerReviewBackgroundText"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="Enter Customer Review Background Text"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Customer Review Title</label>
                    <input
                      type="text"
                      v-model="pageCMSData.customerReviewTitle"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="Enter Customer Review Title"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Customer Review Subtitle</label>
                    <input
                      type="text"
                      v-model="pageCMSData.customerReviewSubtitle"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="Enter Customer Review Subtitle"
                    />
                  </div>

                  <div class="mb-3">
                    <label class="form-label"
                      >Intagram Post Background Text</label
                    >
                    <input
                      type="text"
                      v-model="pageCMSData.instaPostBackgroundText"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="Enter Customer Review Background Text"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Intagram Post Title</label>
                    <input
                      type="text"
                      v-model="pageCMSData.instaPostTitle"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="Enter Customer Review Title"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Intagram Post Subtitle</label>
                    <input
                      type="text"
                      v-model="pageCMSData.instaPostSubtitle"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="Enter Customer Review Subtitle"
                    />
                  </div>

                  <div class="mb-3">
                    <label class="form-label">Home Blog Background Text</label>
                    <input
                      type="text"
                      v-model="pageCMSData.homeBlogBackgroundText"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="Enter Home Blog Background Text"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Home Blog Title</label>
                    <input
                      type="text"
                      v-model="pageCMSData.homeBlogTitle"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="Enter Home Blog Title"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Home Blog Subtitle</label>
                    <input
                      type="text"
                      v-model="pageCMSData.homeBlogSubTitle"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="Enter Home Blog Subtitle"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Button Text</label>
                    <input
                      type="text"
                      v-model="pageCMSData.buttonText"
                      class="form-control"
                      id="inputPageHeaderTitle"
                      placeholder="ex: Shop Now"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Brand Background Image</label>
                    <img
                      style="position: relative; left: 11px"
                      v-if="previewImage"
                      :src="previewImage"
                      height="70"
                      width="100"
                    />
                    <img
                      style="position: relative; left: 11px"
                      v-else-if="pageCMSData.brandBackgroundImage"
                      :src="
                        $imageURL +
                        'home-page-cms/' +
                        pageCMSData.brandBackgroundImage
                      "
                      height="70"
                      width="100"
                    />
                    <img
                      style="position: relative; left: 11px"
                      v-else
                      src="/core/assets/images/400.png"
                      height="70"
                      width="100"
                    />
                    <input
                      class="form-control mt-3"
                      type="file"
                      ref="backgroundImage"
                      accept="image/*"
                      @change="uploadFeaturedImage"
                    />
                  </div>

                  <div class="d-grid">
                    <button
                      v-on:click="updateCms()"
                      type="button"
                      class="btn btn-primary"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/home-page-cms.js"></script>