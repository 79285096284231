<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Newsletter Modal</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">Newsletter CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body p-4">
                    <h5 class="card-title">Newsletter CMS</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="border border-3 p-4 rounded">
                                    <div class="mb-3">
                                        <label class="form-label">Title</label>
                                        <input type="text" v-model="pageCMSData.title" class="form-control" id="inputPageHeaderTitle" placeholder="Enter Title">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Sub Title</label>
                                        <input type="text" v-model="pageCMSData.subTitle" class="form-control" id="inputPageHeaderPageName" placeholder="Enter Subtitle">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Image: <strong>[Preferred Image Size: 516X562, Max Upload Limit: 1MB]</strong></label>
                                        <input class="form-control" type="file" @change="uploadImage($event)" ref="addImage" id="formFile">
                                    </div>
                                    
                                    <div class="mb-3">
                                        <div class="col-md-12">
                                            <img
                                                v-if="previewImage"
                                                :src="previewImage"
                                                height="90"
                                                width="150"
                                            />
                                            <img
                                                v-else-if="pageCMSData.image"
                                                :src="imagePath + pageCMSData.image"
                                                height="90"
                                                width="150"
                                            />
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Company Logo: <strong>[Preferred Image Size: 150X30, Max Upload Limit: 1MB]</strong></label>
                                        <input class="form-control" type="file" @change="uploadLogo($event)" ref="addLogo" id="formFile">
                                    </div>
                                    
                                    <div class="mb-3">
                                        <div class="col-md-12">
                                            <img
                                                v-if="previewLogo"
                                                :src="previewLogo"
                                                height="90"
                                                width="150"
                                            />
                                            <img
                                                v-else-if="pageCMSData.companyLogo"
                                                :src="imagePath + pageCMSData.companyLogo"
                                                height="90"
                                                width="150"
                                            />
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Email Placeholder Text</label>
                                        <input type="text" v-model="pageCMSData.enterEmailPlaceholder" class="form-control" id="inputPageHeaderPageName" placeholder="Enter Email Placeholder Text">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Don't Show Message</label>
                                        <input type="text" v-model="pageCMSData.dontShowMsg" class="form-control" id="inputPageHeaderPageName" placeholder="Enter Don't Show Message">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Button text</label>
                                        <input type="text" v-model="pageCMSData.buttonText" class="form-control" id="inputPageHeaderPageName" placeholder="Enter Button Text">
                                    </div>
                                    
                                    <div class="d-grid">
                                        <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/newsletter-cms.js"></script>
