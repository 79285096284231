import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            setURL: this.$serverURL + this.$api.payment.paymentMethodAPI,
            singleAPI: this.$serverURL + this.$api.payment.singlePaymentMethodAPI,
            methodName: "",
            singleMethod: {},
        }
    },
    async created() {
        document.title = "HGS - Payment";
        await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        addPaymentMethod: async function () {
           
            if (!this.methodName) {
               
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter payment method name!"
                });
            }
            else {
                let data = {
                    name: this.methodName,
                };
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.postDataToBackend(config);
                this.methodName = '';
                await this.getDataList(this.setURL);
            }
        },
        editPaymentMethod: async function (data) {
            this.singleMethod = data;
        },
        updatePaymentMethod: async function () {
           
            if (!this.singleMethod.name) {
               
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter payment method name!"
                });
            }
            else {
                let data = {
                    name: this.singleMethod.name,
                };
                let config = {
                    method: "PATCH",
                    url: this.singleAPI + this.singleMethod.id,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.updateDataToBackend(config);
                this.singleMethod = {};
                await this.getDataList(this.setURL);
            }
        },
        deletePaymentMethod: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then(async(result) => {
                    if (result.isConfirmed) {
                        try {
                            let config = {
                                method: 'DELETE',
                                url: this.singleAPI + id,
                                headers: {
                                    "Authorization": authHeader(),
                                }
                            }
                            await this.deleteDataToBackend(config);
                            await this.getDataList(this.setURL);
                        } catch (error) {
                            this.$swal.fire({
                                icon: "error",
                                text: "Failed! Error!" + error
                            });
                        }
                    }
            })
            
        }
    }
}