<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Pop up Settings
                        </li>
                    </ol>
                </nav>

                <div class="ms-auto"></div>
            </div>
            <h6 class="mb-0 text-uppercase">Pop up Settings</h6>
            <hr />

            <div>
                <form @submit.prevent="updatePopUp()">
                    <div class="card">
                        <div class="card-header"><strong>Pop up Settings</strong></div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group m-3">
                                        <label>Advertise Pop Up</label>
                                        <Multiselect v-model="advertise" mode="tags" :close-on-select="false"
                                            :searchable="true" :create-option="true" @select="()=>{changeAd()}" @deselect="()=>{removeAd()}" :options="adPageList" />
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-group m-3">
                                        <label>Newsletter Pop Up</label>
                                        <Multiselect v-model="newsletter" mode="tags" :close-on-select="false"
                                            :searchable="true" :create-option="true" @select="()=>{changeNewsletter()}" @deselect="()=>{removeNewsletter()}" :options="newsPageList" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <button type="submit" class="btn btn-primary">Submit</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script src='../js/pop-up-settings.js'></script>