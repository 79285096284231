import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            termsConditionData: {
                title: "",
                description: ""
            },
            setURL: this.$serverURL + this.$api.policies.termsAndCondition,
        }

    },
    async created() {
        document.title = "HGS - Terms And Condition";

        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.termsConditionData = this.pageCMS;
        }
        else {
            this.termsConditionData = {
                title: "",
                description: ""
            };
        }
    },
    methods: {
        async updateCms() {
            try {
                let data = {
                    title: this.termsConditionData.title,
                    description: this.termsConditionData.description,
                };
                let config = {
                    method: 'POST',
                    url: this.setURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                await this.createUpdateCMS(config);
                await this.getCmsData(this.setURL);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! " + error
                });
            }
        }
    },
}