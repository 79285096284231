import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            logo: "",
            helpLineText: "",
            helpLineNumber: "",
            searchPlaceholder: "",
            updateLogo: "",
            previewLogo: "",
            setURL: this.$serverURL + this.$api.cms.headerCMS,
            setImageURL: this.$imageURL + 'header/',
            buttonText: "",
            buttonLink: ""
        }
    },
    async mounted() {
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.logo = this.pageCMS.logo;
            this.helpLineText = this.pageCMS.helpLineText;
            this.helpLineNumber = this.pageCMS.helpLineNumber;
            this.searchPlaceholder = this.pageCMS.searchPlaceholder;
            this.buttonText = this.pageCMS.buttonText;
            this.buttonLink = this.pageCMS.buttonLink;
        }
    },
    methods: {
        uploadLogo(event) {
            this.updateLogo = event.target.files[0];
            let input = this.$refs.companyLogo;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewLogo = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        updateCms: async function () {
            try {
                if (!this.helpLineText) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter help line text!"
                    });
                } else if (!this.helpLineNumber) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter help line number!"
                    });
                } else if (!this.searchPlaceholder) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter search placeholder text!"
                    });
                }
                else if(!this.buttonText){
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter search button text!"
                    });
                }
                else if(!this.buttonLink){
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter search button link!"
                    });
                }
                else {
                    let formData = new FormData();
                    if (this.updateLogo) {
                        formData.append("logo", this.updateLogo);
                    } else {
                        formData.append("logo", this.logo);

                    }
                    formData.append("helpLineText", this.helpLineText);
                    formData.append("helpLineNumber", this.helpLineNumber);
                    formData.append("searchPlaceholder", this.searchPlaceholder);
                    formData.append("buttonText", this.buttonText);
                    formData.append("buttonLink", this.buttonLink);
                    let config = {
                        method: 'POST',
                        url: this.setURL,
                        data: formData,
                        headers: {
                            "Authorization": authHeader(),
                        }
                    };
                    await this.createUpdateCMS(config);
                    await this.getCmsData(this.setURL);
                    if (this.pageCMS) {
                        this.logo = this.pageCMS.logo;
                        this.previewLogo = null;
                        this.helpLineText = this.pageCMS.helpLineText;
                        this.helpLineNumber = this.pageCMS.helpLineNumber;
                        this.searchPlaceholder = this.pageCMS.searchPlaceholder;
                        this.buttonText = this.pageCMS.buttonText;
                        this.buttonLink = this.pageCMS.buttonLink;
                    }
                    this.$refs.companyLogo.value = "";

                }

            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! " + error
                });
            }
        }
    }
}