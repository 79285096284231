import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            setURL: '',
            pageCMSData: [],
            previewPaymentImage1: "",
            previewPaymentImage2: "",
            previewPaymentImage3: "",
            previewPaymentImage4: "",
            imagePath: this.$imageURL + 'footer/'
        }
    },
    async mounted() {
        this.setURL = this.$serverURL + this.$api.cms.footerPageCMS;
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
    },
    methods: {
        uploadpaymentImage1: function(event) {
            this.pageCMSData.paymentImageOne = event.target.files[0];
            let input = this.$refs.paymentImage1;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewPaymentImage1 = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadpaymentImage2: function(event) {
            this.pageCMSData.paymentImageTwo = event.target.files[0];
            let input = this.$refs.paymentImage2;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewPaymentImage2 = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadpaymentImage3: function(event) {
            this.pageCMSData.paymentImageThree = event.target.files[0];
            let input = this.$refs.paymentImage3;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewPaymentImage3 = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadpaymentImage4: function(event) {
            this.pageCMSData.paymentImageFour = event.target.files[0];
            let input = this.$refs.paymentImage4;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewPaymentImage4 = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        updateCms: async function() {
            try {
                let formData = new FormData();
                formData.append('menuOneTitle', this.pageCMSData.menuOneTitle);
                formData.append('menuTwoTitle', this.pageCMSData.menuTwoTitle)
                formData.append('menuThreeTitle', this.pageCMSData.menuThreeTitle);
                formData.append('menuFourTitle', this.pageCMSData.menuFourTitle);
                formData.append('contactAddress', this.pageCMSData.contactAddress);
                formData.append('contactNumber', this.pageCMSData.contactNumber);
                formData.append('contactEmail', this.pageCMSData.contactEmail);
                formData.append('emailPlaceHolder', this.pageCMSData.emailPlaceHolder);
                formData.append('followUsText', this.pageCMSData.followUsText);
                formData.append('privacyPolicyText', this.pageCMSData.privacyPolicyText);
                formData.append('privacyPolicyLink', this.pageCMSData.privacyPolicyLink);
                formData.append('termsAndConditionText', this.pageCMSData.termsAndConditionText);
                formData.append('termsAndConditionLink', this.pageCMSData.termsAndConditionLink);
                formData.append('paymentImageOne', this.pageCMSData.paymentImageOne);
                formData.append('paymentImageTwo', this.pageCMSData.paymentImageTwo);
                formData.append('paymentImageThree', this.pageCMSData.paymentImageThree);
                formData.append('paymentImageFour', this.pageCMSData.paymentImageFour);
                formData.append('contactAddressIcon', this.pageCMSData.contactAddressIcon);
                formData.append('contactNumberIcon', this.pageCMSData.contactNumberIcon);
                formData.append('contactEmailIcon', this.pageCMSData.contactEmailIcon);
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.cms.footerPageCMS,
                    data:formData,
                    headers: {
                        "Authorization": authHeader(),
                        "Content-Type": "multipart/form-data",
                    }
                };
                await this.createUpdateCMS(config);
                await this.getCmsData(this.setURL);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! " + error
                });
            }
        }
    }
}