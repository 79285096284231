import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    name: "About Services",
    data() {
        return {
            editor: ClassicEditor,
            teamMemberList: {},
            singleTeamMember: {},
            name: "",
            image: "",
            designation: "",
            previewImage: "",
            updateFeaturedImage: "",
            previewUpdateImage: "",
            setURL: this.$serverURL + this.$api.team.teamMember,
            setSingleTeamMemberURL: this.$serverURL + this.$api.team.singleTeamMember,
            setImageURL: this.$imageURL + 'about/',
        }
    },
    async created() {
        document.title = "HGS - About Services";
        await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        uploadImage(event) {
            this.image = event.target.files[0];
            let input = this.$refs.featuredImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        updateImage(event) {
            this.updateFeaturedImage = event.target.files[0];
            let input = this.$refs.updateFeaturedImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewUpdateImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        addTeamMember: async function () {
           
            if (!this.name || !this.designation || !this.image) {
               
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter name, image, designation!"
                });
            }
            else {
                let formData = new FormData();
                formData.append("name", this.name);
                formData.append("designation", this.designation);
                formData.append("image", this.image);
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: formData,
                    headers: {
                        "Authorization": authHeader(),
                        "Content-Type": "multipart/form-data"
                    }
                };
                await this.postDataToBackend(config);
                this.name = '';
                this.designation = '';
                this.image = '';
                this.previewImage = "";
                this.$refs.featuredImage.value = "",
                await this.getDataList(this.setURL);
            }
        },
        editTeamMember: function (data) {
            this.singleTeamMember = data;
        },
        updateTeamMember: async function () {
            if (!this.singleTeamMember.name || !this.singleTeamMember.designation) {
               
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter Content title, icon class and description!"
                });
            }
            else {
                let formData = new FormData();
                formData.append("name", this.singleTeamMember.name);
                formData.append("designation", this.singleTeamMember.designation);
                if (this.updateFeaturedImage) {
                    formData.append("image", this.updateFeaturedImage);
                } else {
                    formData.append("image", this.singleTeamMember.image);
                }
                let config = {
                    method: "PATCH",
                    url: this.setSingleTeamMemberURL + this.singleTeamMember.id,
                    data: formData,
                    headers: {
                        "Authorization": authHeader(),
                        "Content-Type": "multipart/form-data"
                    }
                };
                await this.updateDataToBackend(config);
                this.singleTeamMember = {};
                this.updateFeaturedImage = "";
                this.previewUpdateImage = "";
                this.$refs.updateFeaturedImage.value = "";
                await this.getDataList(this.setURL);
            }
        },
        deleteTeamMember: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.setSingleTeamMemberURL + id,
                            headers: {
                                "Authorization": authHeader(),
                            }
                        }
                        await this.deleteDataToBackend(config);
                        await this.getDataList(this.setURL);

                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })

        }
    }
}