import { authHeader } from "../../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            setURL: '',
            pageCMSData: []
        }
    },
    async mounted() {
        this.setURL = this.$serverURL + this.$api.cms.aboutPageCMS;
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
    },
    methods: {
        async updateCms() {
            try {
                let data={
                    pageTitle: this.pageCMSData.pageTitle,
                    subTitle: this.pageCMSData.subTitle,
                    pageSubTitle: this.pageCMSData.pageSubTitle,
                    sectionOneTitle: this.pageCMSData.sectionOneTitle,
                    sectionOneSubTitle: this.pageCMSData.sectionOneSubTitle,
                    sectionTwoTitle: this.pageCMSData.sectionTwoTitle,
                    sectionTwoSubTitle: this.pageCMSData.sectionTwoSubTitle
                };
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.cms.aboutPageCMS,
                    data:data,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                await this.createUpdateCMS(config);
                await this.getCmsData(this.setURL);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! " + error
                });
            }
        }
    }
}