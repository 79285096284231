import { authHeader } from "../../../../auth";
import html2pdf from 'html2pdf.js'
export default {
    data() {
        return {
            id: this.$route.params.id,
            orderDetails: {},
            billingAddress: {},
            shippingAddress: {},
            pageCMSData: []
        }
    },
    async created() {
        this.loadJs();
        this.getOrderDetails();
        this.getFooterCMS();
    },
    methods: {
        
        loadJs() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
        },
        getFooterCMS:async function() {
            this.setURL = this.$serverURL + this.$api.cms.footerPageCMS;
            await this.getCmsData(this.setURL);
            if (this.pageCMS) {
                this.pageCMSData = this.pageCMS;
            }
            else {
                this.pageCMSData = [];
            }
        },
        async getOrderDetails() {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.order.orderURL + '/' + this.id,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                await this.$axios(config).then((response) => {
                    if (response.data.statusCode == 200) {
                        this.orderDetails = response.data.Orders[0];
                        this.billingAddress = this.orderDetails?.billingAddress ? JSON.parse(this.orderDetails.billingAddress) : null;
                        this.shippingAddress = this.orderDetails?.shippingAddress ? JSON.parse(this.orderDetails.shippingAddress) : null;
                    }

                }).catch(error => {

                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!" + error
                    });
                });
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later." + error
                });
            }
        },
        generatePDF: function () {
            const doc = document.getElementById('doc');
            // var opt = {
            //     filename: this.orderDetails.orderId + '.pdf',
            //   };
            var opt = {
            filename: this.orderDetails.orderId + '.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' },
            pagebreak: { mode: 'avoid-all', before: '#page2el' }
            };
            html2pdf().from(doc).set(opt).save();
        },

    }
}