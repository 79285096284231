import { authHeader } from "../../../../auth";
export default {
    data() {
        return {
            name: "",
            singleContent: {},
            parentCategoryValue: "",
            setURL: this.$serverURL + this.$api.productCategory.subProductCategoryURL,
            imageURL: this.$imageURL + "category-image/"

        }
    },
    async created() {
        document.title = "HGS - Sub Category";
        await this.getAllParentCategories();
        await this.getSubCategories();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js",
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        validate: function () {
            if (!this.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter sub category name!"
                });
                return false;
            } else if (!this.parentCategoryValue) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select parent category!"
                });
                return false;
            }
            return true;
        },
        addCategory: async function () {

            try {
                if (this.validate()) {
                    let data = {
                        name: this.name,
                        parentCategoryId: this.parentCategoryValue.id,
                        parentCategoryName: this.parentCategoryValue.name,
                    }
                    let config = {
                        method: "POST",
                        url: this.setURL,
                        data: data,
                        headers: {
                            "Authorization": authHeader(),
                            "Content-Type": "application/json",
                        }
                    };
                    await this.postDataToBackend(config);
                    await this.getSubCategories();
                    this.name = null;
                    this.parentCategoryValue = "";
                }
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong!!" + error,
                });
            }

        },
        editCategory: function (data) {
            this.singleContent = data;
        },
        updateCategory: async function () {
            let data = {
                name: this.singleContent.name
            }
            if (this.parentCategoryValue) {
                data.parentCategoryId = this.parentCategoryValue.id;
                data.parentCategoryName = this.parentCategoryValue.name;
            } else {
                data.parentCategoryId = this.singleContent.parentCategoryId;
                data.parentCategoryName = this.singleContent.parentCategoryName;
            }
            let config = {
                method: "PATCH",
                url: this.setURL + "/" + this.singleContent.id,
                data: data,
                headers: {
                    "Authorization": authHeader(),
                }
            };
            await this.updateDataToBackend(config);
            await this.getSubCategories();
            this.singleContent = {};
            this.parentCategoryValue = "";
        },
        deleteCategory: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.setURL + "/" + id,
                            headers: {
                                "Authorization": authHeader(),
                            }
                        }
                        await this.deleteDataToBackend(config);
                        await this.getAllParentCategories();
                        await this.getSubCategories();
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })

        }
    }
}