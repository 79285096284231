<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"
                ><i class="bx bx-home-alt"></i
              ></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Featured Categories
            </li>
          </ol>
        </nav>

        <div class="ms-auto"></div>
      </div>
      <h6 class="mb-0 text-uppercase">Featured Catgories</h6>
      <hr />

      <div>
        <form @submit.prevent="updateCategories()">
          <div class="card-header"><strong>Featured Catgories</strong></div>

          <div class="row">
            <div class="col-sm">
              <div class="card">
                <div class="card-header">First Category</div>
                <div class="card-body">
                  <div class="form-group m-3">
                    <select v-model="firstCategory" class="form-select mb-3">
                      <option style="display: none" value="" selected>
                        Select First Category
                      </option>
                      <option
                        v-for="(data, index) in categoryList"
                        :key="index"
                        :value="{ id: data.id, name: data.name }"
                      >
                        {{ data.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group m-3">
                    <label for="exampleInputEmail1" class="form-label"
                      >Current Image</label
                    >
                    &ensp;
                    <img
                      style="position: relative; left: 11px"
                      v-if="previewImage1"
                      :src="previewImage1"
                      height="70"
                      width="100"
                    />
                    <img
                      style="position: relative; left: 11px"
                      v-else-if="featuredCategory.featuredImage1"
                      :src="$imageURL + 'featured-categories-image/' + featuredCategory.featuredImage1"
                      height="70"
                      width="100"
                    />
                    <img
                      style="position: relative; left: 11px"
                      v-else
                      src="/core/assets/images/400.png"
                      height="70"
                      width="100"
                    />
                  </div>
                  <!-- <div class="form-group m-3" v-if="previewImage1">
                    <label for="exampleInputEmail1" class="form-label"
                      >Uploaded Image</label
                    >&ensp;
                    <img
                      v-if="previewImage1"
                      :src="previewImage1"
                      height="70"
                      width="100"
                    />
                  </div> -->
                  <div class="form-group m-3">
                    <label>Featured Image</label>
                    <input
                      class="form-control"
                      type="file"
                      ref="featuredImage1"
                      accept="image/*"
                      @change="uploadFeaturedImage1"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm">
              <div class="card">
                <div class="card-header">Second Category</div>
                <div class="card-body">
                  <div class="form-group m-3">
                    <select v-model="secondCategory" class="form-select mb-3">
                      <option style="display: none" value="" selected>
                        Select Second Category
                      </option>
                      <option
                        v-for="(data, index) in categoryList"
                        :key="index"
                        :value="{ id: data.id, name: data.name }"
                      >
                        {{ data.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group m-3">
                    <label for="exampleInputEmail1" class="form-label"
                      >Current Image</label
                    >&ensp;
                    <img
                      style="position: relative; left: 11px"
                      v-if="previewImage2"
                      :src="previewImage2"
                      height="70"
                      width="100"
                    />
                    <img
                      style="position: relative; left: 11px"
                      v-else-if="featuredCategory.featuredImage2"
                      :src="$imageURL + 'featured-categories-image/' + featuredCategory.featuredImage2"
                      height="70"
                      width="100"
                    />
                    <img
                      style="position: relative; left: 11px"
                      v-else
                      src="/core/assets/images/400.png"
                      height="70"
                      width="100"
                    />
                  </div>
                  <!-- <div class="form-group m-3" v-if="previewImage2">
                    <label for="exampleInputEmail1" class="form-label"
                      >Uploaded Image</label
                    >&ensp;
                    <img
                      v-if="previewImage2"
                      :src="previewImage2"
                      height="70"
                      width="100"
                    />
                  </div> -->
                  <div class="form-group m-3">
                    <label>Featured Image</label>
                    <input
                      class="form-control"
                      type="file"
                      ref="featuredImage2"
                      accept="image/*"
                      @change="uploadFeaturedImage2"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm">
              <div class="card">
                <div class="card-header">Third Category</div>
                <div class="card-body">
                  <div class="form-group m-3">
                    <select v-model="thirdCategory" class="form-select mb-3">
                      <option style="display: none" value="" selected>
                        Select Third Category
                      </option>
                      <option
                        v-for="(data, index) in categoryList"
                        :key="index"
                        :value="{ id: data.id, name: data.name }"
                      >
                        {{ data.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group m-3">
                    <label for="exampleInputEmail1" class="form-label"
                      >Current Image</label
                    >&ensp;
                    <img
                      style="position: relative; left: 11px"
                      v-if="previewImage3"
                      :src="previewImage3"
                      height="70"
                      width="100"
                    />
                    <img
                      style="position: relative; left: 11px"
                      v-else-if="featuredCategory.featuredImage3"
                      :src="$imageURL + 'featured-categories-image/' + featuredCategory.featuredImage3"
                      height="70"
                      width="100"
                    />
                    <img
                      style="position: relative; left: 11px"
                      v-else
                      src="/core/assets/images/400.png"
                      height="70"
                      width="100"
                    />
                  </div>
                  <!-- <div class="form-group m-3" v-if="previewImage3">
                    <label for="exampleInputEmail1" class="form-label"
                      >Uploaded Image</label
                    >&ensp;
                    <img
                      v-if="previewImage3"
                      :src="previewImage3"
                      height="70"
                      width="100"
                    />
                  </div> -->
                  <div class="form-group m-3">
                    <label>Featured Image</label>
                    <input
                      class="form-control"
                      type="file"
                      ref="featuredImage3"
                      accept="image/*"
                      @change="uploadFeaturedImage3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script src='../js/featured.js'></script>
