import { authHeader } from "../../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            pageCMSData: [],
            setURL: this.$serverURL + this.$api.cms.productDetailsCMS,
        }
    },
    async mounted() {
        document.title = "HGS - Product Details CMS";
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
    },
    methods: {
        async update() {
            try {
                if(!this.pageCMSData.pageTitle){
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter title!"
                    });
                }
                else if(!this.pageCMSData.pageSubTitle) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter Subtitle!"
                    });
                }
                else if(!this.pageCMSData.backgroundText) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter background text!"
                    });
                }
                else {
                    let data = {
                        pageTitle: this.pageCMSData.pageTitle,
                        pageSubTitle: this.pageCMSData.pageSubTitle,
                        backgroundText: this.pageCMSData.backgroundText
                    };
                    let config = {
                        method: 'POST',
                        url: this.setURL,
                        data: data,
                        headers: {
                            "Authorization": authHeader(),
                        }
                    };
                    await this.createUpdateCMS(config);
                    await this.getCmsData(this.setURL);
                    
                }
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! " + error
                });
            }
        }
    }
}