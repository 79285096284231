import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            setURL: '',
            pageCMSData: []
        }
    },
    async mounted() {
        this.setURL = this.$serverURL + this.$api.cms.contactPageCMS;
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
    },
    methods: {
        async updateCms() {
            try {
                let data={
                    title: this.pageCMSData.title,
                    subTitle: this.pageCMSData.subTitle,
                    contactFormTitle: this.pageCMSData.contactFormTitle,
                    buttonText: this.pageCMSData.buttonText,
                    googleMapLink: this.pageCMSData.googleMapLink,

                };
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.cms.contactPageCMS,
                    data:data,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                await this.createUpdateCMS(config);
                await this.getCmsData(this.setURL);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! " + error
                });
            }
        }
    }
}