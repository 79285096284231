import { authHeader } from "../../../auth";

export default {
    name: "AddLocation",
    data() {
        return {
            location: '',
            contactNumber: '',
            email: '',
            timeFrame: '',
            mapLink: null
        }
    },
    async created() {
        document.title = "HGS- Location";
    },
    methods: {
        validate: function() {
            if(!this.location) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter location!"
                });
                return false;
            }
            if(!this.contactNumber) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter contact number!"
                });
                return false;
            }
            if(!this.email) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter email!"
                });
                return false;
            }
            if(!this.timeFrame) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter time frame!"
                });
                return false;
            }
            if(!this.mapLink) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter map link!"
                });
                return false;
            }
            return true;
        },
        save: async function() {
            if(this.validate() == true) {
                let data = {
                    location: this.location,
                    contactNumber: this.contactNumber,
                    email: this.email,
                    timeFrame: this.timeFrame,
                    mapLink: this.mapLink
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.location.requestURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.$axios(config).then((response) => {
                    if(response.status == 201) {
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully created data!"
                        });
                    }
                    this.$router.push("/location-list");
                }).catch(error => {
                    console.log(error);
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}