import { createApp } from 'vue';
import App from './App.vue';
import CKEditor from '@ckeditor/ckeditor5-vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import router from './routes/routes';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Multiselect from '@vueform/multiselect';
import '@vueform/multiselect/themes/default.css';
import moment from 'moment';
import mixins from './mixin'
const app = createApp(App);
app.config.globalProperties.$api = require('../config/api.json');
const server = require('../config/server.json');
app.use(VueAxios, axios);
app.use(VueSweetalert2);
app.component('Multiselect', Multiselect);
app.use(CKEditor);
app.config.globalProperties.$filters = {
    formatDate(date) {
        return moment(String(date)).format('DD/MM/YYYY hh:mm:ss A');
    },
    formatCouponDate(date) {
        return moment(String(date)).format('DD/MM/YYYY');
    },
    formatUpdateDate(date) {
        return moment(String(date)).format('DD MMMM YYYY');
    },
};
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$hostURL = server.HOST;
app.config.globalProperties.$serverURL = server.SERVER_HOST;
app.config.globalProperties.$imageURL = server.IMAGE_URL;

app.mixin(mixins);
app.use(router).mount('#app');