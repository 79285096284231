const pages = [
    "Home",
    "Products",
    "Account",
    "Blogs",
    "Contact",
    "Help",
    "BlogDetails",
    "ProductDetails",
    "About",
    "Cart",
    "Wishlist",
    "Checkout",
    "Track Order",
    "Profile"
]
module.exports = {pages};