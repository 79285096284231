<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"
                ><i class="bx bx-home-alt"></i
              ></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Parent Category List
            </li>
          </ol>
        </nav>

        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
            >
              <i class="bx bxs-plus-square"></i>Add New Category
            </button>
          </div>
          <!-- Add Modal -->

          <div
            class="modal fade"
            id="addModal"
            tabindex="-1"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
            <form @submit.prevent="addCategory">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">Add Category</h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Name:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="name"
                      placeholder="Name"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="form-group m-3">
                    <label for="exampleInputEmail1"
                      >Featured Image: <strong>[Preferred Image Size: 600X400, Max Upload Limit: 1MB]</strong></label
                    >
                    <input
                      class="form-control mb-3"
                      type="file"
                      ref="addFeaturedImage"
                      aria-describedby="imageHelp"
                      accept="image/*"
                      @change="uploadFeaturedImage($event)"
                    />
                    <img
                      v-if="previewImage"
                      :src="previewImage"
                      height="90"
                      width="150"
                    />
                    <img
                      v-else
                      src="/core/assets/images/400.png"
                      height="90"
                      width="150"
                    />
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <!-- Add Modal -->
          <!-- Edit Modal -->

          <div
            class="modal fade"
            id="editModal"
            tabindex="-1"
            aria-labelledby="editModalLabel"
            aria-hidden="true"
          >
            <form @submit.prevent="updateCategory()">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">
                      Edit Category
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Name:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="singleContent.name"
                      placeholder="Name"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="form-group m-3">
                    <label for="exampleInputEmail1"
                      >Current Image: &ensp;</label
                    >
                    <img
                      v-if="singleContent.image"
                      :src="imageURL + singleContent.image"
                      alt=""
                      height="80"
                      width="110"
                    />
                    <img
                      v-else
                      src="/core/assets/images/400.png"
                      height="90"
                      width="150"
                    />
                  </div>
                  <div class="form-group m-3">
                    <label for="exampleInputEmail1"
                      >Featured Image: <strong>[Preferred Image Size: 600X400, Max Upload Limit: 1MB]</strong></label
                    >
                    <input
                      class="form-control mb-3"
                      type="file"
                      ref="updateFeaturedImage"
                      aria-describedby="imageHelp"
                      accept="image/*"
                      @change="updateFeaturedImage($event)"
                    />
                    <img
                      v-if="previewUpdateImage"
                      :src="previewUpdateImage"
                      height="90"
                      width="150"
                    />
                    <img
                      v-else
                      src="/core/assets/images/400.png"
                      height="90"
                      width="150"
                    />
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <!-- Edit Category Modal -->
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Parent Category List</h6>
      <hr />

      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Name</th>
                  <th>Image</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in parentCategoriesList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.name }}</td>
                  <td>
                    <img
                      v-if="data.image"
                      :src="imageURL + data.image"
                      height="80"
                      width="120 "
                      alt=""
                    />
                    <img
                      v-else
                      src="/core/assets/images/400.png"
                      height="80"
                      width="120 "
                      alt=""
                    />
                  </td>
                  <td>
                    <div class="d-flex order-actions">
                      <a
                        href="javascript:void(0);"
                        v-on:click="editCategory(data)"
                        data-bs-toggle="modal"
                        data-bs-target="#editModal"
                        class="edit-button"
                        ><i class="bx bxs-edit text-white"></i
                      ></a>
                      <a
                        href="javascript:void(0);"
                        v-on:click="deleteCategory(data.id)"
                        class="ms-3 delete-button"
                        ><i class="bx bxs-trash text-white"></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>SL</th>
                  <th>Name</th>
                  <th>Image</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/parent-category.js'></script>


<style scoped>
.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>