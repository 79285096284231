<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"
                ><i class="bx bx-home-alt"></i
              ></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Return Policy Cms
            </li>
          </ol>
        </nav>

        <div class="ms-auto"></div>
      </div>
      <h6 class="mb-0 text-uppercase">Return Policy Settings</h6>
      <hr />

      <div>
        <form @submit.prevent="updateCms()">
          <div class="card">
            <div class="card-header"><strong>Return Policy Settings</strong></div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-group m-3">
                    <label>Title</label>
                    <input
                      type="text"
                      v-model="returnPolicyData.title"
                      class="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Enter title"
                      required
                    />
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group m-3">
                    <label>Description</label>
                    <ckeditor
                      :editor="editor"
                      v-model="returnPolicyData.description"
                      :config="editorConfig"
                    ></ckeditor>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script src='../js/return-policy.js'></script>
