import { createRouter, createWebHistory } from "vue-router";
import guard from "../guard";
import Login from "../components/auth/template/Login";
import Dashboard from "../components/dashboard/template/Dashboard";
import Error404 from "../components/global/error_404/template/Error404";
import Unauthorized from "../components/global/unauthorized/template/Unauthorized";
import Customer from "../components/user/template/Customer";
import Admin from "../components/user/template/Admin";
import ProductReview from "../components/Product/ProductReview/template/ProductReview";
import Product from "../components/Product/ProductList/template/Product";
import ProductReorder from "../components/Product/ProductReorder/template/ProductReorder";
import AddProduct from "../components/Product/AddProduct/template/AddProduct";
import UpdateProduct from "../components/Product/UpdateProduct/template/UpdateProduct";
import Brand from "../components/Product/Brand/template/Brand";
import ImportBrand from "../components/Product/Brand/template/ImportBrand";
import Size from "../components/Product/Size/template/Size";
import Color from "../components/Product/Color/template/Color";
import Discount from "../components/Product/Discount/template/Discount";
import ParentCategory from "../components/Product/ProductCategory/template/ParentCategory";
import SubCategory from "../components/Product/ProductCategory/template/SubCategory";
import Contact from "../components/Contact/template/Contact";
import ContactMessageList from "../components/Contact/template/ContactMessageList";
import AboutServices from "../components/AboutUs/AboutServices/template/AboutServices";
import AboutContent from "../components/AboutUs/AboutContent/template/AboutContent";
import ProductDetailsCMS from "../components/CMS/Product/ProductDetailsCMS/template/ProductDetailsCMS";
import ProductCMS from "../components/CMS/Product/ProductCMS/template/ProductCMS";
import Slider from "../components/Slider/template/Slider";
import ReviewSlider from "../components/ReviewSlider/template/ReviewSlider";
import ContactPageCMS from "../components/CMS/Contact/template/ContactPageCMS";
import BlogCMS from "../components/CMS/BlogPageCMS/template/BlogCMS";
import AdPopModalCMS from "../components/CMS/PopUpModalCMS/AdPopUpModal/template/AdPopModalCMS";
import NewsletterCMS from "../components/CMS/PopUpModalCMS//NewsletterModalCMS/template/NewsletterCMS";
import SocialLinks from "../components/CMS/Common/SocialLinks/template/SocialLinks";
import HomePageCMS from "../components/CMS/Common/HomePageCMS/template/HomePageCMS";
import AboutPageCMS from "../components/CMS/AboutUs/AboutPageCMS/template/AboutPageCMS";
import TrackOrderCMS from "../components/CMS/TrackOrderCMS/template/TrackOrderCMS";
import HelpPageCms from "../components/CMS/HelpCMS/template/HelpPageCms";
import HelpPageContent from "../components/HelpUs/template/HelpPageContent";
import HeaderCms from "../components/CMS/HeaderFooterCMS/template/HeaderCms";
import FooterCms from "../components/CMS/HeaderFooterCMS/template/FooterCms";
import FranchiseCMS from "../components/CMS/Franchise/template/FranchiseCMS";
import BlogCategory from "../components/Blog/BlogCategory/template/BlogCategory";
import BlogComment from "../components/Blog/BlogComment/template/BlogComment";
import BlogTags from "../components/Blog/BlogTags/template/BlogTags";
import BlogPost from "../components/Blog/BlogPost/template/BlogPost"
import AddBlogPost from "../components/Blog/AddBlogPost/template/AddBlogPost"
import UpdateBlogPost from "../components/Blog/UpdateBlogPost/template/UpdateBlogPost";
import Profile from "../components/profile/template/Profile";
import OrderList from "../components/Order/OrderList/template/OrderList";
import CreateOrder from "../components/Order/CreateOrder/template/CreateOrder";
import TeamMember from "../components/TeamMember/template/TeamMember";
import OrderDetails from "../components/Order/OrderDetails/template/OrderDetails";
import Coupon from "../components/Coupon/template/Coupon";
import PaymentMethod from "../components/PaymentMethod/template/PaymentMethod";
import PopUpSettings from "../components/PopUpSettings/template/PopUpSettings";
import FeaturedCategories from "../components/FeaturedCategories/template/FeaturedCategories";
import Subscriber from "../components/Subscriber/template/Subscriber";
import Franchise from "../components/Franchise/template/Franchise";
import PrivacyPolicy from "../components/policies/privacy_policy/template/PrivacyPolicy";
import CancellationPolicy from "../components/policies/cancellation_policy/template/CancellationPolicy";
import ReturnPolicy from "../components/policies/return_policy/template/ReturnPolicy";
import TermsAndCondition from "../components/policies/terms_and_condition/template/TermsAndCondition";
import ProductBulkUpload from "../components/Product/ProductBulkUpload/template/ProductBulkUpload";
import ProductStockIn from "../components/Product/ProductStockIn/template/ProductStockIn";
import StockList from "../components/Product/StockList/template/StockList";
import StockDetail from "../components/Product/StockDetail/template/StockDetail";
import LocationList from "../components/location/template/LocationList";
import AddLocation from "../components/location/template/AddLocation";
import EditLocation from "../components/location/template/EditLocation";
import EnquiryList from "../components/location/template/EnquiryList";
import InstagramFeed from "../components/InstagramFeed/template/InstagramFeed";
import LocationCms from "../components/CMS/LocationCMS/template/LocationCms";
import Ups from "../components/ShippingSettings/Ups/template/Ups";
import Fedex from "../components/ShippingSettings/Fedex/template/Fedex";
import PaymentSetting from "../components/PaymentSettings/template/PaymentSetting";
import PaymentList from "../components/PaymentList/template/PaymentList";
import ChildCategory from "../components/Product/ProductCategory/template/ChildCategory";

const routes = [
    /* Auth Routes */
    { path: "/admin-login", name: "Login", component: Login },
    { path: "/", redirect: { name: 'Home' } },
    { path: "/home", name: "Home", component: Dashboard, beforeEnter: guard },
    { path: "/profile", name: "Profile", component: Profile, beforeEnter: guard },
    { path: "/admins", name: "Admin", component: Admin, beforeEnter: guard },
    { path: "/customers", name: "Customer", component: Customer, beforeEnter: guard },

    //Product
    { path: "/product-list", name: "Product", component: Product, beforeEnter: guard },
    { path: "/product-reorder", name: "ProductReorder", component: ProductReorder, beforeEnter: guard },
    { path: "/product-review", name: "ProductReview", component: ProductReview, beforeEnter: guard },
    { path: "/product-brand", name: "Brand", component: Brand, beforeEnter: guard },
    { path: "/brand-import", name: "ImportBrand", component: ImportBrand, beforeEnter: guard },
    { path: "/add-product", name: "AddProduct", component: AddProduct, beforeEnter: guard },
    { path: "/update-product/:id", name: "UpdateProduct", component: UpdateProduct, beforeEnter: guard },
    { path: "/product-size", name: "Size", component: Size, beforeEnter: guard },
    { path: "/product-color", name: "Color", component: Color, beforeEnter: guard },
    { path: "/product-discount", name: "Discount", component: Discount, beforeEnter: guard },
    { path: "/parent-category", name: "ParentCategory", component: ParentCategory, beforeEnter: guard },
    { path: "/sub-category", name: "SubCategory", component: SubCategory, beforeEnter: guard },
    { path: "/child-category", name: "ChildCategory", component: ChildCategory, beforeEnter: guard },
    { path: "/product-bulk-upload", name: "ProductBulkUpload", component: ProductBulkUpload, beforeEnter: guard },
    { path: "/stock-in-product", name: "ProductStockIn", component: ProductStockIn, beforeEnter: guard },
    { path: "/stock-list", name: "StockList", component: StockList, beforeEnter: guard },
    { path: "/stock-detail/:id", name: "StockDetail", component: StockDetail, beforeEnter: guard },

    //Contact
    { path: "/contact", name: "Contact", component: Contact, beforeEnter: guard },
    { path: "/contact-message", name: "ContactMessageList", component: ContactMessageList, beforeEnter: guard },
    { path: "/slider", name: "Slider", component: Slider, beforeEnter: guard },
    { path: "/review-slider", name: "ReviewSlider", component: ReviewSlider, beforeEnter: guard },

    //Intagram feed
    { path: "/brands", name: "InstagramFeed", component: InstagramFeed, beforeEnter: guard },

    //Blog
    { path: "/blog-category", name: "BlogCategory", component: BlogCategory, beforeEnter: guard },
    { path: "/blog-tags", name: "BlogTags", component: BlogTags, beforeEnter: guard },
    { path: "/blog-post", name: "BlogPost", component: BlogPost, beforeEnter: guard },
    { path: "/add-blog-post", name: "AddBlogPost", component: AddBlogPost, beforeEnter: guard },
    { path: "/update-blog-post/:id", name: "UpdateBlogPost", component: UpdateBlogPost, beforeEnter: guard },
    { path: "/blog-comments", name: "BlogComment", component: BlogComment, beforeEnter: guard },
    { path: "/blog-cms", name: "BlogCMS", component: BlogCMS, beforeEnter: guard },

    //About Us
    { path: "/about-services", name: "AboutServices", component: AboutServices, beforeEnter: guard },
    { path: "/about-content", name: "AboutContent", component: AboutContent, beforeEnter: guard },

    //Help content
    { path: "/help-content", name: "HelpPageContent", component: HelpPageContent, beforeEnter: guard },

    //Team Member
    { path: "/team-member", name: "TeamMember", component: TeamMember, beforeEnter: guard },

    //Coupon
    { path: "/coupon", name: "Coupon", component: Coupon, beforeEnter: guard },
    { path: "/payment-method", name: "PaymentMethod", component: PaymentMethod, beforeEnter: guard },
    { path: "/pop-up-settings", name: "PopUpSettings", component: PopUpSettings, beforeEnter: guard },
    { path: "/featured-categories", name: "FeaturedCategories", component: FeaturedCategories, beforeEnter: guard },

    //CMS
    { path: "/header-cms", name: "HeaderCMS", component: HeaderCms, beforeEnter: guard },
    { path: "/footer-cms", name: "FooterCMS", component: FooterCms, beforeEnter: guard },
    { path: "/product-cms", name: "ProductCMS", component: ProductCMS, beforeEnter: guard },
    { path: "/product-details-cms", name: "ProductDetailsCMS", component: ProductDetailsCMS, beforeEnter: guard },
    { path: "/home-page-cms", name: "HomePageCMS", component: HomePageCMS, beforeEnter: guard },
    { path: "/social-links", name: "SocialLinks", component: SocialLinks, beforeEnter: guard },
    { path: "/contact-cms", name: "ContactPageCMS", component: ContactPageCMS, beforeEnter: guard },
    { path: "/about-page-cms", name: "AboutPageCMS", component: AboutPageCMS, beforeEnter: guard },
    { path: "/ad-pop-up-cms", name: "AdPopModalCMS", component: AdPopModalCMS, beforeEnter: guard },
    { path: "/newsletter-cms", name: "NewsletterCMS", component: NewsletterCMS, beforeEnter: guard },
    { path: "/track-order-cms", name: "TrackOrderCMS", component: TrackOrderCMS, beforeEnter: guard },
    { path: "/help-page-cms", name: "HelpPageCms", component: HelpPageCms, beforeEnter: guard },
    { path: "/subscriber", name: "Subscriber", component: Subscriber, beforeEnter: guard },
    { path: "/franchise", name: "Franchise", component: Franchise, beforeEnter: guard },
    { path: "/franchise-cms", name: "FranchiseCMS", component: FranchiseCMS, beforeEnter: guard },
    { path: "/location-cms", name: "LocationnCMS", component: LocationCms, beforeEnter: guard },

    //Order
    { path: "/orders", name: "OrderList", component: OrderList, beforeEnter: guard },
    { path: "/create-order", name: "CreateOrder", component: CreateOrder, beforeEnter: guard },
    { path: "/order-details/:id", name: "OrderDetails", component: OrderDetails, beforeEnter: guard },

    //policies
    { path: "/privacy-policy", name: "Privacy Policy", component: PrivacyPolicy, beforeEnter: guard },
    { path: "/return-policy", name: "Return Policy", component: ReturnPolicy, beforeEnter: guard },
    { path: "/cancellation-policy", name: "Cancellation Policy", component: CancellationPolicy, beforeEnter: guard },
    { path: "/terms&condition", name: "Terms and Condition", component: TermsAndCondition, beforeEnter: guard },

    /* Error and Unauthorized routes */
    { path: "/404-not-found", name: "404 Not Found", component: Error404, beforeEnter: guard },
    { path: "/unauthorized", name: "Unauthorized", component: Unauthorized, beforeEnter: guard },
    { path: '/:pathMatch(.*)*', component: Error404, beforeEnter: guard },

    // Our Location
    { path: "/location-list", name: "LocationList", component: LocationList, beforeEnter: guard },
    { path: "/add-new-location", name: "AddLocation", component: AddLocation, beforeEnter: guard },
    { path: "/edit-location", name: "EditLocation", component: EditLocation, beforeEnter: guard },
    { path: "/enquiry-list", name: "EnquiryList", component: EnquiryList, beforeEnter: guard },

    //shipping settins
    {path: "/ups-settings", name: 'Ups', component: Ups, beforeEnter: guard},
    {path: "/fedex-settings", name: 'Fedex', component: Fedex, beforeEnter: guard},

    //payment setting
    { path: "/payment-settings", name: 'PaymentSetting', component: PaymentSetting, beforeEnter: guard },
    { path: "/payment-list", name: 'PaymentList', component: PaymentList, beforeEnter: guard },

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
