<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"
                ><i class="bx bx-home-alt"></i
              ></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Blog Comment List
            </li>
          </ol>
        </nav>

        <div class="ms-auto"></div>
      </div>
      <h6 class="mb-0 text-uppercase">Blog Comment List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Website</th>
                  <th>Blog Name</th>
                  <th>Comment</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{index+1}}</td>
                  <td>{{data.name}}</td>
                  <td>{{data.email}}</td>
                  <td>{{data.website}}</td>
                  <td>{{data.postTitle}}</td>
                  <td>{{data.comment}}</td>
                  <td>{{data.createdAt}}</td>

                  <td>
                    <div class="d-flex order-actions">
                      <a
                        href="javascript:void(0);"
                        v-on:click="deleteBlogComment(data)"
                        class="ms-3 delete-button"
                        ><i class="bx bxs-trash text-white"></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>SL</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Website</th>
                  <th>Blog Name</th>
                  <th>Comment</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/blog-comment.js'></script>


<style scoped>
/* @import "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css"; */
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
</style>
