import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            name: '',
            featuredImage: "",
            shortDescription: '',
            longDescription: '',
            price: '',
            cost: '',
            discounted_price: '',
            sku: '',
            stock: '',
            weight: '',
            isFeatured: false,
            parentCategory: "",
            subCategory: "",
            childCategory: "",
            subCategoryList: [],
            childCategoryList: [],
            colorValue: [],
            colorsList: [],
            sizeValue: [],
            sizesList: [],
            brand: {},
            brandList: [],
            discount: {
                id: '',
                name: ''
            },
            discountList: [],
            previewFeaturedImage: null,
            productImageList: [],
            previewProductImages: [],
            disableButton: false,
            setURL: this.$serverURL + this.$api.product.productAPI,
            discountTitle: '',
            quantityReorder: 0,
        }

    },
    async mounted() {
        await this.getAllVarient();
        await this.getParentCategoriesWithSubcategory();
    },
    methods: {
        uploadFeaturedImage: function () {
            this.featuredImage = event.target.files[0];
            let input = this.$refs.featuredImageInput
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewFeaturedImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadProductImages: function () {
            this.productImageList.push(this.$refs.productImages.files[0]);
            if (this.productImageList.length > 3) {
                this.disableButton = true;
            }
            let input = this.$refs.productImages
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewProductImages.push(e.target.result)
                }
                reader.readAsDataURL(file[0])
            }
            this.$refs.productImages.value = null;
        },
        removeUploadedProductImage: function (index) {
            this.previewProductImages.splice(index, 1);
            this.productImageList.splice(index, 1);
            if (this.productImageList.length > 3) {
                this.disableButton = true;
            } else {
                this.disableButton = false;
            }
        },
        getAllVarient: async function () {
            let url = this.$serverURL + this.$api.product.getVarient;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    // this.colorsList = response.data.colorData.map((el) => {
                    //     return { value: el.colorName, label: el.colorName }
                    // });
                    // this.sizesList = response.data.sizeData.map(el => {
                    //     return { value: el.sizeName, label: el.sizeName }
                    // });
                    this.brandList = response.data.brandData;
                    this.discountList = response.data.discountData;
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getSubCategoryList: async function (id) {
            this.subCategoryList = [];
            this.subCategory = "";
            this.childCategoryList = [];
            this.childCategory = "";
            let url = this.$serverURL + this.$api.productCategory.getSubCategoriesURL + '?parentCategoryId=' + id;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    this.subCategoryList = response.data.data;
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getChildCategoryList: async function (id) {
            this.childCategoryList = [];
            this.childCategory = "";
            let url = this.$serverURL + this.$api.productCategory.childCategoryURL + '?subCategoryId=' + id;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    this.childCategoryList = response.data.data;
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        changePrice: function () {
            if (this.discount.name != "None") {
                if (this.discount.name.includes("%")) {
                    let converted_discount = parseFloat(this.discount.name) / 100.0;
                    this.discounted_price = this.price - this.price * converted_discount;
                }
                else {
                    try {
                        let converted_discount = parseInt(this.discount.name)
                        this.discounted_price = this.price - converted_discount;
                    }
                    catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again.Error:" + error
                        });
                    }
                }
            } else {
                this.discounted_price = null;
            }
        },
        addDiscount: async function () {

            if (!this.discountTitle) {

                this.$swal.fire({
                    icon: "error",
                    text: "Please enter discount title!"
                });
            }
            else {
                let data = {
                    discountTitle: this.discountTitle,
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.product.productDiscountAPI,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.$axios(config).then(async (response) => {
                    if (response.status == 201) {

                        this.discount = {
                            id: response.data.data.id,
                            name: response.data.data.discountTitle
                        };
                        this.discountTitle = '';
                        await this.getAllVarient();
                        await this.changePrice();
                    }
                    else {

                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again." + error
                    });
                });

            }
        },
        checkValidation: function () {
            if (!this.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product name!"
                });
                return false;
            } else if (!this.parentCategory.id) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter category!"
                });
                return false;
            } else if (!this.featuredImage) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter featured image!"
                });
                return false;
            } else if (this.productImageList.length == 0) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product image!"
                });
                return false;
            } else if (!this.price) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product price!"
                });
                return false;
            } else if (!this.cost) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product cost!"
                });
                return false;
            } else if (!this.brand.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter brand!"
                });
                return false;
            } else {
                return true;
            }

        },
        addProduct: async function () {
            this.$swal.showLoading();
            if (this.checkValidation()) {

                if(this.quantityReorder ==  null)
                {
                    this.quantityReorder = 0;
                }

                if(this.quantityReorder ==  "")
                {
                    this.quantityReorder = 0;
                }

                let formData = new FormData();
                formData.append("name", this.name);
                formData.append("shortDescription", this.shortDescription);
                formData.append("longDescription", this.longDescription);
                formData.append("parentCategoryId", this.parentCategory.id);
                formData.append("parentCategoryName", this.parentCategory.name);
                formData.append("subCategoryId", this.subCategory.id);
                formData.append("subCategoryName", this.subCategory.name);
                formData.append("childCategoryId", this.childCategory.id);
                formData.append("childCategoryName", this.childCategory.name);
                formData.append("featuredImage", this.featuredImage);
                formData.append("sku", this.sku);
                formData.append("price", this.price);
                formData.append("stock", this.stock);
                formData.append("quantityReorder", this.quantityReorder);
                formData.append("discountedPrice", this.discounted_price ? this.discounted_price : 0);
                formData.append("brandName", this.brand.name);
                formData.append("discountId", this.discount?.id ? this.discount.id : '');
                formData.append("discountTitle", (this.discount?.name && this.discount.name != 'None') ? this.discount.name : '');
                this.productImageList.map((el) => {
                    formData.append("productImages", el);
                })
                // this.colorValue.map((el) => {
                //     formData.append("color[]", el);
                // });
                // this.sizeValue.map((el) => {
                //     formData.append("size[]", el);
                // });
                formData.append('cost', this.cost);
                formData.append("weight", this.weight);
                formData.append("isFeatured", this.isFeatured ? 1 : 0);
                let config = {
                    method: "POST",
                    data: formData,
                    url: this.setURL,
                    headers: {
                        "Authorization": authHeader()
                    }
                }
                await this.$axios(config).then(async (response) => {
                    this.$swal.hideLoading();
                    if (response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.$router.push("/product-list");
                    }
                    else {

                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {
                    if (error.response.data) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                    else {
                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }

                });
            }
        }
    },
    watch: {
        price: function () {
            if (this.discount.name) {
                this.changePrice();
            }
        }
    }
}