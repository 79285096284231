<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <a href="javascript:;"><i class="bx bx-home-alt"></i></a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Create Order
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!--end breadcrumb-->

      <div class="card">
        <div class="card-body p-4">
          <h5 class="card-title">Create Order</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-sm-6">
                <div class="card radius-10">
                  <div class="card-body">
                    <label for="inputProductTitle" class="form-label"
                      ><strong>Customer Information</strong></label
                    ><br />
                    <label
                      for="inputProductTitle"
                      class="form-label"
                      style="margin-right: 5px"
                      ><strong>Customer Type: {{ customerType }}</strong></label
                    ><br />
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="exampleRadios1"
                        v-model="isExistingUser"
                        checked
                        :value="false"
                      />
                      <label class="form-check-label" for="exampleRadios1"
                        >New</label
                      >
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="inlineCheckbox2"
                        v-model="isExistingUser"
                        :value="true"
                      />
                      <label class="form-check-label" for="inlineCheckbox2"
                        >Existing</label
                      >
                    </div>

                    <div class="mb-3" v-if="isExistingUser">
                      <label for="inputProductTitle" class="form-label"
                        >Select Customer</label
                      >
                      <Multiselect
                        v-model="userDetails"
                        id="inputVendor"
                        @select="
                          () => {
                            setUserData(userDetails);
                          }
                        "
                        :options="userList"
                        :searchable="true"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="inputProductTitle" class="form-label"
                        >Name</label
                      >
                      <input
                        type="email"
                        class="form-control"
                        id="inputProductTitle"
                        v-model="userName"
                        placeholder="Enter name"
                      />
                    </div>
                    <div class="mb-3">
                      <div class="row">
                        <div class="col-sm mb-3">
                          <label for="inputProductTitle" class="form-label"
                            >Email</label
                          >
                          <input
                            type="email"
                            class="form-control"
                            id="inputProductTitle"
                            v-model="userEmail"
                            placeholder="Enter email"
                          />
                        </div>
                        <div class="col-sm mb-3">
                          <label for="inputProductTitle" class="form-label"
                            >Contact No</label
                          >
                          <input
                            type="tel"
                            class="form-control"
                            id="inputProductTitle"
                            v-model="userMobile"
                            placeholder="Enter phone no"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="card radius-10">
                  <div class="card-body">
                    <label for="inputProductTitle" class="form-label"
                      ><strong>Shipping Information</strong></label
                    >
                    <div class="mb-3">
                      <div class="row">
                        <div class="col">
                          <label for="inputProductTitle" class="form-label"
                            >Name</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="inputProductTitle"
                            v-model="shippingAddressData.name"
                            placeholder="Enter name"
                          />
                        </div>
                        <div class="col">
                          <label for="inputProductTitle" class="form-label"
                            >Business Name</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="inputProductTitle"
                            v-model="shippingAddressData.businessName"
                            placeholder="Enter name"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="row">
                        <div class="col">
                          <label for="inputProductTitle" class="form-label"
                            >Street Address One</label
                          >
                          <textarea
                            class="form-control"
                            id="floatingTextarea"
                            v-model="shippingAddressData.addressOne"
                            placeholder="Enter street address one"
                          ></textarea>
                        </div>
                        <div class="col">
                          <label for="inputProductTitle" class="form-label"
                            >Street Address Two (Optional)</label
                          >
                          <textarea
                            class="form-control"
                            id="floatingTextarea"
                            v-model="shippingAddressData.addressTwo"
                            placeholder="Enter street address two"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="row">
                        <div class="col-sm">
                          <label for="inputProductTitle" class="form-label"
                            >City</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="inputProductTitle"
                            v-model="shippingAddressData.city"
                            placeholder="Enter city"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="row">
                        <div class="col-sm">
                          <label for="inputProductTitle" class="form-label"
                            >Zip Code</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="inputProductTitle"
                            v-model="shippingAddressData.postCode"
                            placeholder="Enter zip code"
                          />
                        </div>
                        <div class="col-sm">
                          <label for="inputProductTitle" class="form-label"
                            >State Code</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="inputProductTitle"
                            v-model="shippingAddressData.stateCode"
                            placeholder="Enter state code"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="row">
                        <div class="col">
                          <label for="inputProductTitle" class="form-label"
                            >Email</label
                          >
                          <input
                            type="email"
                            class="form-control"
                            id="inputProductTitle"
                            v-model="shippingAddressData.email"
                            placeholder="Enter email"
                          />
                        </div>
                        <div class="col-sm">
                          <label for="inputProductTitle" class="form-label"
                            >Contact No</label
                          >
                          <input
                            type="tel"
                            class="form-control"
                            id="inputProductTitle"
                            v-model="shippingAddressData.mobile"
                            placeholder="Enter phone no"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="row">
                        <div class="col-sm">
                          <!-- <label for="inputProductTitle" class="form-label"
                            >Country</label
                          > -->
                          <!-- <Multiselect
                            v-model="shippingCountry"
                            id="inputVendor"
                            :options="countryList"
                            :searchable="true"
                          /> -->
                          <div class="form-group" hidden>
                            <label>Country</label>
                            <select
                              class="form-control form-select"
                              v-model="shippingCountry"
                              name="countryName"
                            >
                              <option
                                v-for="(data, index) in countries"
                                :key="index"
                                :value="{
                                  name: data.countryNameEn,
                                  code: data.countryCode,
                                }"
                              >
                                {{ data.countryNameEn }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="row"></div>
                    </div>
                    <!-- <div id="chart2"></div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 mb-3">
                <div class="border border-3 p-4 rounded">
                  <div class="row g-3">
                    <div class="invoice-body">
                      <!-- Row start -->
                      <div class="row gutters">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <div class="table-responsive">
                            <table class="table custom-table m-0">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Product</th>
                                  <th scope="col">Qty</th>
                                  <th scope="col">Unit Price</th>
                                  <th scope="col">Total Amount</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(data, index) in addedProductList"
                                  :key="index"
                                >
                                  <td scope="row">{{ index + 1 }}</td>
                                  <td scope="row" style="width: 20%">
                                    <Multiselect
                                      v-model="productValue[index]"
                                      @select="
                                        () => {
                                          productData(
                                            productValue[index],
                                            index
                                          );
                                        }
                                      "
                                      :options="productList"
                                      :searchable="true"
                                    />
                                  </td>
                                  <!-- <td>
                                    <select
                                      class="form-select"
                                      id="inputVendor"
                                      v-model="colorValue[index]"
                                      :onChange="
                                        () => {
                                          selectColor(index);
                                        }
                                      "
                                    >
                                      <option
                                        style="display: none"
                                        selected
                                        value=""
                                      >
                                        Select Color
                                      </option>
                                      <option
                                        v-for="(data, index) in selectedProduct[
                                          index
                                        ]?.colors"
                                        :key="index"
                                        :value="data.colorName"
                                      >
                                        {{ data.colorName }}
                                      </option>
                                    </select>
                                  </td>
                                  <td>
                                    <select
                                      class="form-select"
                                      v-model="sizeValue[index]"
                                      id="inputVendor"
                                      :onChange="
                                        () => {
                                          selectSize(index);
                                        }
                                      "
                                    >
                                      <option
                                        style="display: none"
                                        selected
                                        value=""
                                      >
                                        Select size
                                      </option>
                                      <option
                                        v-for="(data, index) in selectedProduct[
                                          index
                                        ]?.sizes"
                                        :key="index"
                                        :value="data.sizeName"
                                      >
                                        {{ data.sizeName }}
                                      </option>
                                    </select>
                                  </td> -->
                                  <td scope="row">
                                    <button
                                      class="btn btn-danger btn-sm"
                                      @click="decreaseQuantity(index)"
                                    >
                                      -
                                    </button>
                                    &ensp;
                                    {{
                                      selectedProduct[index]
                                        ? selectedProduct[index]?.quantity
                                          ? selectedProduct[index]?.quantity
                                          : 1
                                        : 0
                                    }}
                                    &ensp;
                                    <button
                                      @click="increaseQuantity(index)"
                                      class="btn btn-success btn-sm"
                                    >
                                      +
                                    </button>
                                  </td>
                                  <th scope="row">
                                    <!-- {{
                                      selectedProduct[index]?.discountedPrice
                                        ? selectedProduct[index]?.discountedPrice
                                        : selectedProduct[index]?.price
                                        ? selectedProduct[index]?.price
                                        : 0
                                    }} -->
                                    <input
                                      type="text"
                                      v-model="selectedProduct[index].price"
                                      v-on:keyup="calculateTotal"
                                      @change="calculateTotal"
                                      class="form-control"
                                      v-if="selectedProduct[index]"
                                    />
                                  </th>
                                  <th scope="row">
                                    {{
                                      selectedProduct[index]?.price
                                        ? selectedProduct[index]?.price *
                                          selectedProduct[index]?.quantity
                                        : selectedProduct[index]
                                            ?.discountedPrice
                                        ? selectedProduct[index]
                                            ?.discountedPrice *
                                          selectedProduct[index]?.quantity
                                        : 0
                                    }}
                                  </th>
                                  <th>
                                    <span
                                      :hidden="addedProductList.length < 2"
                                      class="badge bg-danger"
                                      @click="removeItem(index)"
                                      >Remove</span
                                    >
                                  </th>
                                </tr>
                                <tr>
                                  <td colspan="5">&nbsp;</td>
                                  <td style="float: right">
                                    <button
                                      class="btn btn-sm btn-success"
                                      @click="addProduct()"
                                    >
                                      Add More
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="4">
                                    <div
                                      class="card radius-10"
                                      v-if="selectedProduct.length > 0"
                                    >
                                      <div class="card-body">
                                        <label
                                          for="inputProductTitle"
                                          class="form-label"
                                          ><strong
                                            >Shipping Method</strong
                                          ></label
                                        ><br />
                                        <div
                                          class="form-check form-check-inline"
                                        >
                                          <input
                                            class="form-check-input"
                                            type="radio"
                                            v-model="shippingType"
                                            :value="'fedex'"
                                            @click="getServiceList"
                                          />
                                          <label class="form-check-label"
                                            ><a
                                              href="javascript:void(0)"
                                              class="float-end"
                                              ><img
                                                src="/core/assets/images/fedx.png"
                                                width="70"
                                                height="34"
                                                alt="" /></a
                                          ></label>
                                        </div>
                                        <div
                                          class="form-check form-check-inline"
                                        >
                                          <input
                                            class="form-check-input"
                                            type="radio"
                                            v-model="shippingType"
                                            @click="getUpsServiceList"
                                            :value="'ups'"
                                          />
                                          <label class="form-check-label"
                                            ><a
                                              href="javascript:void(0)"
                                              class="float-end"
                                              ><img
                                                src="/core/assets/images/ups.png"
                                                width="70"
                                                height="34"
                                                alt="" /></a
                                          ></label>
                                        </div>
                                        <div
                                          class="mb-3"
                                          v-if="shippingType == 'fedex'"
                                        >
                                          <div
                                            class="row mt-2"
                                            v-if="
                                              serviceTypeList &&
                                              serviceTypeList.length > 0
                                            "
                                          >
                                            <h6 class="pb-2">Service Type</h6>
                                            <div class="form-group">
                                              <ul>
                                                <li
                                                  v-for="(
                                                    data, index
                                                  ) in serviceTypeList"
                                                  :key="index"
                                                >
                                                  <label class="container_radio"
                                                    >{{ data.serviceName
                                                    }}<a
                                                      href="javascript:void(0)"
                                                      class="float-end"
                                                      style="color: black"
                                                      >${{ data.price }}</a
                                                    >
                                                    <input
                                                      type="radio"
                                                      v-model="serviceType"
                                                      @click="
                                                        setShippingCost(
                                                          data.price
                                                        )
                                                      "
                                                      :value="data.serviceType"
                                                    />
                                                    <span
                                                      class="checkmark"
                                                    ></span>
                                                  </label>
                                                  <hr />
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="mb-3"
                                          v-else-if="shippingType == 'ups'"
                                        >
                                          <div
                                            class="row mt-2"
                                            v-if="
                                              serviceTypeList &&
                                              serviceTypeList.length > 0
                                            "
                                          >
                                            <h6 class="pb-2">Service Type</h6>
                                            <div class="form-group">
                                              <ul>
                                                <li
                                                  v-for="(
                                                    data, index
                                                  ) in serviceTypeList"
                                                  :key="index"
                                                >
                                                  <label class="container_radio"
                                                    >{{ data.serviceName
                                                    }}<a
                                                      href="javascript:void(0)"
                                                      class="float-end"
                                                      style="color: black"
                                                      >${{ data.price }}</a
                                                    >
                                                    <input
                                                      type="radio"
                                                      v-model="serviceType"
                                                      @click="
                                                        setShippingCost(
                                                          data.price
                                                        )
                                                      "
                                                      :value="data.serviceType"
                                                    />
                                                    <span
                                                      class="checkmark"
                                                    ></span>
                                                  </label>
                                                  <hr />
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <textarea
                                      class="form-control"
                                      v-model="remarks"
                                      placeholder="Leave a comment here"
                                      id="floatingTextarea2"
                                      style="height: 100px"
                                    ></textarea>
                                  </td>
                                  <td colspan="1">
                                    <p>Subtotal<br /></p>
                                    <p v-if="shippingCost">
                                      Shipping Cost<br />
                                    </p>
                                    <h5 class="text-success mb-5">
                                      <strong>Discount</strong>
                                    </h5>

                                    <h5 class="text-primary">
                                      <strong>Grand Total</strong>
                                    </h5>
                                  </td>
                                  <td>
                                    <p>${{ subTotal }}</p>
                                    <p v-if="shippingCost">
                                      ${{ shippingCost }}
                                    </p>
                                    <p>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="discount"
                                        v-on:keyup="addDiscount"
                                        @change="addDiscount"
                                        placeholder="ex: 10%, 20% or 10, 20"
                                      />
                                    </p>
                                    <h5
                                      class="text-primary"
                                      style="
                                        position: absolute;
                                        margin-top: 19px;
                                      "
                                    >
                                      <strong v-if="total > 0"
                                        >${{
                                          parseFloat(
                                            total + shippingCost
                                          ).toFixed(2)
                                        }}</strong
                                      >
                                      <strong v-else>$0</strong>
                                    </h5>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <br />
                          <button
                            class="btn btn-success"
                            @click="createOrder()"
                          >
                            Confirm Order
                          </button>
                        </div>
                      </div>
                      <!-- Row end -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end row-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.container_check {
  display: block;
  position: relative;
  padding-left: 30px;
  line-height: 1.7;
  margin-bottom: 8px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.container_check input:checked ~ .checkmark {
  background-color: #004dda;
  border: 1px solid transparent;
}

.container_check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #dddddd;
  background-color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.container_check .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.container_check input:checked ~ .checkmark:after {
  display: block;
}

/* Radio buttons */
.container_radio {
  display: block;
  position: relative;
  padding-left: 30px;
  line-height: 1.7;
  margin-bottom: 8px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container_radio input {
  position: absolute;
  opacity: 0;
}

.container_radio input:checked ~ .checkmark:after {
  opacity: 1;
}

.container_radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #dddddd;
  background-color: #fff;
  border-radius: 50%;
}

.container_radio .checkmark:after {
  display: block;
  content: "";
  position: absolute;
  opacity: 0;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #004dda;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
</style>
<script src="../js/create-order.js"></script>