<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Help Page CMS</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard"
                  ><i class="bx bx-home-alt"></i
                ></router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Help Page CMS
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body p-4">
          <h5 class="card-title">Help Page CMS</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-12">
                <div class="border border-3 p-4 rounded mb-4">
                  <div class="mb-3">
                    <label for="inputProductTitle" class="form-label"
                      >Title<span style="color: red; font-size: 18px">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="inputTitle"
                      placeholder="Enter title"
                      v-model="pageCMSData.title"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="inputProductDescription" class="form-label"
                      >Subtitle <span style="color: red; font-size: 18px">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="inputTitle"
                      v-model="pageCMSData.subTitle"
                      placeholder="Enter Subtitle"
                    />
                  </div>
                  <!-- <div class="mb-3">
                    <label class="form-label">Search Text <span style="color: red; font-size: 18px">*</span></label>
                    <input
                      type="text"
                      v-model="pageCMSData.searchText"
                      class="form-control"
                      id="inputPageHeaderTitle"
                      placeholder="Enter Search text"
                    />
                  </div> -->
                  <div class="mb-3">
                    <label class="form-label">Support Section Title <span style="color: red; font-size: 18px">*</span></label>
                    <input
                      type="text"
                      v-model="pageCMSData.supportSectionTitle"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="Enter Support Section Title"
                    />
                  </div>
                  <div class="mb-3">
                    <label>Support Section SubTitle <span style="color: red; font-size: 18px">*</span></label>
                    <input
                      type="text"
                      v-model="pageCMSData.supportSectionSubTitle"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="Enter Support Section SubTitle"
                    />
                  </div>
                  <div class="d-grid">
                    <button
                      v-on:click="updateCms()"
                      type="button"
                      class="btn btn-primary"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!--end row-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/help-page-cms.js"></script>