<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Blog Page CMS</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">Blog Page CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Blog Page CMS</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="border border-3 p-4 rounded">
                                    <div class="mb-3">
                                        <label class="form-label">Title</label>
                                        <input type="text" v-model="pageCMSData.title" class="form-control" id="inputPageHeaderTitle" placeholder="Enter Title">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Search Placeholder Text</label>
                                        <input type="text" v-model="pageCMSData.searchPlaceholder" class="form-control" id="inputPageHeaderPageName" placeholder="Enter Search Placeholder">
                                    </div>
                                    <div class="d-grid">
                                        <button v-on:click="updateCms()" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/blog-cms.js"></script>