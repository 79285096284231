import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    name: "About Services",
    data() {
        return {
            editor: ClassicEditor,
            setURL: this.$serverURL + this.$api.home.reviewSliderAPI,
            singleAPI: this.$serverURL + this.$api.home.reviewSliderDetails,
            singleSlider: {},
            reviewerName: '',
            reviewDescription: '',
            rating: ''
        }
    },
    async created() {
        document.title = "HGS - Review Slider";
        await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        addSlider: async function () {
           
            if(!this.reviewerName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter Reviewer Name!"
                });
            }
            else if(!this.reviewDescription) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter Review Description!"
                });
            }
            else if(!this.rating) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter Rating!"
                });
            }
            else {
                let data = {
                    reviewerName: this.reviewerName,
                    reviewDescription: this.reviewDescription,
                    rating: this.rating
                };
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                await this.postDataToBackend(config);
                this.reviewerName = '';
                this.reviewDescription = '';
                this.rating = '';
                await this.getDataList(this.setURL);
            }
        },
        editSlider: async function (data) {
            this.singleSlider = data;
        },
        updateSlider: async function () {
            if(!this.singleSlider.reviewerName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter Reviewer Name!"
                });
            }
            else if(!this.singleSlider.reviewDescription) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter Review Description!"
                });
            }
            else if(!this.singleSlider.rating) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter Rating!"
                });
            }
            else {
                let data = {
                    reviewerName: this.singleSlider.reviewerName,
                    reviewDescription: this.singleSlider.reviewDescription,
                    rating: this.singleSlider.rating
                };
                let config = {
                    method: "PATCH",
                    url: this.singleAPI + this.singleSlider.id,
                    data: data,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                await this.updateDataToBackend(config);
                this.singleSlider = {};
                await this.getDataList(this.setURL);
            }
        },
        deleteSlider: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then(async (result) => {
                if (result.isConfirmed) {
                    if (result.isConfirmed) {
                        try {
                            let config = {
                                method: 'DELETE',
                                url: this.singleAPI + id,
                                headers: {
                                    "Authorization": authHeader(),
                                }
                            }
                            await this.deleteDataToBackend(config);
                            await this.getDataList(this.setURL);
    
                        } catch (error) {
                            this.$swal.fire({
                                icon: "error",
                                text: "Something went wrong, please try agian later." + error
                            });
                        }
                    }
                }
            })
            
        }
    }
}