export default {
    data() {
        return {
            email: null,
            password: null,
            inputType: "password",
            iconFlag: true
        }
    },
    created() {
        document.title = "HGS - Login";
    },
    methods: {
        login: function () {
            if (!this.email || !this.password) {
               
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter e-mail and password!"
                });
            }
            else {
                let data = {
                    email: this.email,
                    password: this.password
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.auth.adminLoginURL,
                    data: data
                };
                this.$axios(config).then((response) => {
                    if (response.status == 201) {
                        localStorage.setItem("token", response.data.token);
                        localStorage.setItem("id", response.data.user.id);
                        localStorage.setItem("firstname", response.data.user.firstName);
                        localStorage.setItem("lastname", response.data.user.lastName);
                        localStorage.setItem("name", response.data.user.firstName + " " + response.data.user.lastName);
                        localStorage.setItem("email", response.data.user.email);
                        localStorage.setItem("mobile", response.data.user.mobile);
                        // this.$router.push("/home");
                        // window.location.replace("/home");
                        window.location.href='/home'
                    }
                    else {
                       
                        this.$swal.fire({
                            icon: "error",
                            text: "Incorrect e-mail and password combination!"
                        });
                    }
                }).catch(error => {
                   
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed to login! " + error
                    });
                })
            }
        },
        changeInputType: function () {
            if (this.inputType == "password") {
                this.inputType = "text";
                this.iconFlag = false;
            }
            else if (this.inputType == "text") {
                this.inputType = "password";
                this.iconFlag = true;
            }
        }
    }
}