import { authHeader } from "../../../auth";
export default {
    name: "Admins",
    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
            userType: 'admin',
            password: '',
            confirmPassword: '',
            inputTypePassword: "password",
            inputTypeConfirmPassword: "password",
            iconStatus: true,
            iconFlag: true,
            gender: '',
            setURL: '',
            singleAdmin: {}
        }
    },
    async created() {
        document.title = "HGS - Admin";
        this.setURL = this.$serverURL + this.$api.user.adminUserList,
            
        await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        addAdmin: async function () {
           
            if (!this.firstName || !this.lastName || !this.email || !this.gender || !this.mobile || !this.password || !this.confirmPassword) {
               
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter firstname, lastname, gender, email and password!"
                });
            }
            else if (this.password != this.confirmPassword) {
               
                this.$swal.fire({
                    icon: "error",
                    text: "Password didn't match!"
                });
            }
            else {
                let data = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    gender: this.gender,
                    password: this.password,
                    mobile: this.mobile,
                    userType: this.userType,
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.user.regUserURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.postDataToBackend(config);
                this.firstName = '';
                this.lastName = '';
                this.email = '';
                this.mobile = '';
                this.userType = 'admin';
                this.password = '';
                this.confirmPassword = '';
                this.inputTypePassword = "password";
                this.inputTypeConfirmPassword = "password";
                await this.getDataList(this.setURL);
            }
        },
        editAdmin: function(data) {
            this.singleAdmin = data;
        },
        updateAdmin: async function() {
           
            if (!this.singleAdmin.firstName || !this.singleAdmin.lastName || !this.singleAdmin.gender || !this.singleAdmin.mobile) {
               
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter firstname, lastname, gender!"
                });
            }
            else {
                let data = {
                    firstName: this.singleAdmin.firstName,
                    lastName: this.singleAdmin.lastName,
                    //email: this.singleAdmin.email,
                    gender: this.singleAdmin.gender,
                    mobile: this.singleAdmin.mobile,
                    userType: this.singleAdmin.userType,
                };
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.user.singleUserDetails + this.singleAdmin.id,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.updateDataToBackend(config);
            }
        },
        changePasswordType: function () {
            if (this.inputTypePassword == "password") {
                this.inputTypePassword = "text";
                this.iconStatus = false;
            }
            else if (this.inputTypePassword == "text") {
                this.inputTypePassword = "password";
                this.iconStatus = true;
            }
        },
        changeConfirmPasswordType: function () {
            if (this.inputTypeConfirmPassword == "password") {
                this.inputTypeConfirmPassword = "text";
                this.iconFlag = false;
            }
            else if (this.inputTypeConfirmPassword == "text") {
                this.inputTypeConfirmPassword = "password";
                this.iconFlag = true;
            }
        },
        deleteAdmin: async function(id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then(async(result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.$serverURL + this.$api.user.singleUserDetails + id,
                            headers: {
                                "Authorization": authHeader(),
                            }
                        }
                        await this.deleteDataToBackend(config);
                        await this.getDataList(this.setURL);
                        
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })
        }
    }
}