<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="container">
        <div class="row gutters">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="card">
              <div class="card-body p-0">
                <div class="invoice-container" id="doc">
                  <div class="invoice-header">
                    <div class="row gutters">
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div class="mb-3 text-center mt-4">
                          <img src="/core/assets/images/hgs_logo.png" alt="">
                          <p v-if="pageCMSData">{{ pageCMSData.contactAddress }}</p>
                        </div>
                      </div>
                    </div>
                    <!-- Row end -->
                    <!-- Row start -->
                    <div class="row gutters">
                      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12" id="contnet" v-if="
                        billingAddress &&
                        Object.values(billingAddress).length > 0
                      ">
                        <div class="invoice-details">
                          <p class="text-muted">
                            <strong>Billing Information</strong>
                          </p>
                          <ul class="list-unstyled">
                            <li class="text-muted">
                              <i class="fas fa-circle" style="color: #84b0ca"></i>
                              <span class="fw-bold">Name:</span>{{
                                  billingAddress?.name
                                    ? billingAddress?.name
                                    : "Not available"
                              }},
                            </li>
                            <li class="text-muted">
                              <i class="fas fa-circle" style="color: #84b0ca"></i>
                              <span class="fw-bold">Contact:</span>{{
                                  billingAddress?.mobile
                                    ? billingAddress?.mobile
                                    : "Not available"
                              }},
                            </li>
                            <li class="text-muted">
                              <i class="fas fa-circle" style="color: #84b0ca"></i>
                              <span class="fw-bold">Email:</span>{{
                                  billingAddress?.email
                                    ? billingAddress?.email
                                    : "Not available"
                              }},
                            </li>
                            <li class="text-muted">
                              <div v-if="billingAddress">
                                <i class="fas fa-circle" style="color: #84b0ca"></i>
                                <span class="fw-bold">Address:</span>
                                {{
                                    billingAddress?.address
                                      ? billingAddress?.address
                                      : ""
                                }}
                                {{
                                    billingAddress?.city
                                      ? "," + billingAddress?.city
                                      : ""
                                }}
                                {{
                                    billingAddress?.postCode
                                      ? "," + billingAddress?.postCode
                                      : ""
                                }}
                                {{
                                    billingAddress?.country.name
                                      ? "," + billingAddress?.country.name
                                      : ""
                                }}
                              </div>
                              <div v-else>
                                <i class="fas fa-circle" style="color: #84b0ca"></i>
                                <span class="fw-bold">Address:</span>
                                Not available
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                        <div class="invoice-details">
                          <p class="text-muted">
                            <strong>Shipping Information</strong>
                          </p>
                          <ul class="list-unstyled">
                            <li class="text-muted">
                              <i class="fas fa-circle" style="color: #84b0ca"></i>
                              <span class="fw-bold">Name:</span>{{
                                  shippingAddress?.name
                                    ? shippingAddress?.name
                                    : "Not available"
                              }},
                            </li>
                            <li class="text-muted">
                              <i class="fas fa-circle" style="color: #84b0ca"></i>
                              <span class="fw-bold">Contact:</span>{{
                                  shippingAddress?.mobile
                                    ? shippingAddress?.mobile
                                    : "Not available"
                              }},
                            </li>
                            <li class="text-muted">
                              <i class="fas fa-circle" style="color: #84b0ca"></i>
                              <span class="fw-bold">Email:</span>{{
                                  shippingAddress?.email
                                    ? shippingAddress?.email
                                    : "Not available"
                              }},
                            </li>
                            <li class="text-muted">
                              <div v-if="shippingAddress">
                                <i class="fas fa-circle" style="color: #84b0ca"></i>
                                <span class="fw-bold">Address:</span>
                                {{
                                    shippingAddress?.addressOne
                                      ? shippingAddress?.addressOne
                                      : ""
                                }}
                                {{
                                    shippingAddress?.city
                                      ? "," + shippingAddress?.city
                                      : ""
                                }}
                                {{
                                    shippingAddress?.postCode
                                      ? "," + shippingAddress?.postCode
                                      : ""
                                }}
                                {{
                                    shippingAddress?.country
                                      ? "," + shippingAddress?.country
                                      : ""
                                }}
                              </div>
                              <div v-else>
                                <i class="fas fa-circle" style="color: #84b0ca"></i>
                                <span>Address:</span>
                                Not available
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                        <div class="invoice-details">
                          <p class="text-muted"><strong>Invoice</strong></p>
                          <ul class="list-unstyled">
                            <li class="text-muted">
                              <i class="fas fa-circle" style="color: #84b0ca"></i>
                              <span class="fw-bold">ID:</span>#{{
                                  orderDetails.orderId
                              }}
                            </li>
                            <li class="text-muted" v-if="orderDetails.trackingNumber">
                              <i class="fas fa-circle" style="color: #84b0ca"></i>
                              <span class="fw-bold">Tracking Number:</span> {{
                                  orderDetails.trackingNumber
                              }}
                            </li>
                            <li class="text-muted">
                              <i class="fas fa-circle" style="color: #84b0ca"></i>
                              <span class="fw-bold">Creation Date: </span>{{
                                  this.$filters.formatDate(orderDetails.createdAt)
                              }}
                            </li>
                            <li class="text-muted">
                              <i class="fas fa-circle" style="color: #84b0ca"></i>
                              <span class="me-1 fw-bold">Payment Status:</span><span :class="[
                                orderDetails.paymentStatus == 'PENDING'
                                  ? 'badge bg-warning'
                                  : orderDetails.paymentStatus == 'Paid'
                                    ? 'badge bg-success'
                                    : 'badge bg-danger',
                                'text-black fw-bold',
                              ]">
                                {{ orderDetails.paymentStatus }}</span>
                            </li>
                            <li class="text-muted">
                              <i class="fas fa-circle" style="color: #84b0ca"></i>
                              <span class="me-1 fw-bold">Delivery Status:</span><span :class="[
                                orderDetails.status == 'PENDING'
                                  ? 'badge bg-primary'
                                  : orderDetails.status == 'PROCESSING'
                                    ? 'badge bg-info'
                                    : orderDetails.status == 'SHIPPED'
                                      ? 'badge bg-warning'
                                      : orderDetails.status == 'DELIVERED'
                                        ? 'badge bg-success'
                                        : 'badge bg-danger',
                              ]">
                                {{ orderDetails.status }}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- Row end -->
                  </div>
                  <div class="invoice-body">
                    <!-- Row start -->
                    <div class="row gutters">
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="table-responsive">
                          <table class="table custom-table m-0">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Qty</th>
                                <th scope="col">Unit Price</th>
                                <th scope="col">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(data, index) in orderDetails.orders" :key="index">
                                <th scope="row">{{ index + 1 }}</th>
                                <td>{{ data.name }}</td>
                                <td>{{ data.quantity }}</td>
                                <td>${{ data.itemPrice }}</td>
                                <td>${{ data.itemSubTotal }}</td>
                              </tr>
                              <tr>
                                <td colspan="3">&nbsp;</td>
                                <td colspan="1">
                                  <p>
                                    Subtotal<br />
                                    <!-- Shipping &amp; Handling<br /> -->
                                    <!-- Tax<br /> -->
                                  </p>
                                  <p>
                                    Shipping Cost<br />
                                  </p>
                                  <p v-if="orderDetails.couponDiscount != '0'">
                                    Coupon discount<br />
                                    <!-- Shipping &amp; Handling<br /> -->
                                    <!-- Tax<br /> -->
                                  </p>
                                  <p v-else-if="
                                    orderDetails.subTotal !=
                                    orderDetails.total
                                  ">
                                    Discount
                                  </p>
                                  <h5 class="text-success">
                                    <strong>Grand Total</strong>
                                  </h5>
                                </td>
                                <td>
                                  <p>
                                    ${{ orderDetails.subTotal }}<br />
                                    <!-- $100.00<br /> -->
                                    <!-- $49.00<br /> -->
                                  </p>
                                  <p>
                                    ${{ orderDetails.shippingCost }}<br />
                                  </p>
                                  <p v-if="orderDetails.couponDiscount != '0'">
                                    - ${{ orderDetails.couponDiscount }}<br />
                                    <!-- $100.00<br /> -->
                                    <!-- $49.00<br /> -->
                                  </p>
                                  <p v-else-if="
                                    orderDetails.subTotal !=
                                    orderDetails.total
                                  ">
                                    -${{
                                        orderDetails.subTotal - orderDetails.total
                                    }}
                                  </p>
                                  <h5 class="text-success">
                                    <strong>${{
                                        orderDetails.couponDiscount
                                          ? ((parseFloat(orderDetails.total) + parseFloat(orderDetails.shippingCost)) -
                                          parseInt(orderDetails.couponDiscount)).toFixed(2)
                                          : (parseFloat(orderDetails.total) + parseFloat(orderDetails.shippingCost)).toFixed(2)
                                    }}</strong>
                                  </h5>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <!-- Row end -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row gutters">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="custom-actions-btns mb-5">
              <!-- <a href="#" class="btn btn-primary">
                            <i class="icon-download"></i> Download
                          </a> -->
              <a href="javascript:void(0)" class="btn btn-secondary" @click="generatePDF">
                <i class="icon-printer"></i> Print
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/order-details.js"></script>

<style scoped>
@import "../css/order-details.css";
</style>
