<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Track Order CMS</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Track Order CMS
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body p-4">
          <h5 class="card-title">Track Order CMS</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-12">
                <div class="border border-3 p-4 rounded mb-4">
                  <div class="mb-3">
                    <label for="inputProductTitle" class="form-label">Title</label>
                    <input type="text" class="form-control" id="inputTitle" placeholder="Enter title"
                      v-model="pageCMSData.title" />
                  </div>
                  <div class="mb-3">
                    <label for="inputProductTitle" class="form-label">Button Text</label>
                    <input type="text" class="form-control" id="inputTitle" placeholder="Enter Button text"
                      v-model="pageCMSData.buttonText" />
                  </div>
                  <div class="mb-3">
                    <label for="inputProductDescription" class="form-label">Search PlaceHolder</label>
                    <input type="text" class="form-control" id="inputTitle" v-model="pageCMSData.placeholder"
                      placeholder="Search Text" />
                  </div>
                  <div class="mb-3">
                    <label for="exampleInputEmail1">Icon: <strong>[Preferred Image Size: 200X177, Max Upload Limit: 1MB]</strong></label>
                    <input class="form-control mb-2" type="file" name="featured_image" id="featuredIcon"
                      ref="featuredIconImage" aria-describedby="imageHelp" accept="image/*" @change="uploadIcon" />
                    <img v-if="previewIconImage" :src="previewIconImage" height="90" width="150" />
                    <img v-else :src="$imageURL + 'track-order/' + pageCMSData.image" height="90" width="150" />
                    <div id="display_image"></div>
                  </div>
                  <div class="d-grid">
                    <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
            </div>
            <!--end row-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/track-order-cms.js"></script>