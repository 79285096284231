import { authHeader } from "../../../../auth";
import { countries } from "../../../../../config/country";
export default {
    data() {
        return {
            productValue: [],
            selectedProduct: [],
            productURL: this.$serverURL + this.$api.product.productAPI,
            addedProductList: [{
                productId: ""
            }],
            confirmedProduct: [],
            colorValue: [''],
            sizeValue: [''],
            subTotal: 0,
            grandTotal: 0,
            isExistingUser: null,
            setURL: this.$serverURL + this.$api.user.customerUserList,
            userList: [],
            userDetails: "",
            userName: "",
            userEmail: "",
            userMobile: "",
            shippingName: "",
            shippingEmail: "",
            shippingAddress1: "",
            shippingAddress2: "",
            shippingContactNo: "",
            shippingCountry: {
                name: 'United States of America',
                code: 'US',
            },
            shippingPostCode: "",
            stateCode: "",
            shippingCity: "",
            remarks: "",
            productList: [],
            discount: '',
            total: 0,
            idx: 0,
            priceList: [],
            countries: countries,
            countryList: [],
            shippingType: "",
            serviceTypeList: [],
            serviceType: "",
            packageType: "",
            shippingRemarks: "",
            totalWeight: 0,
            shippingAddressData: {},
            shippingCost: 0,
        }
    },
    async created() {
        document.title = "HGS - Order";
        await this.getDataList(this.productURL);
        this.productList = this.dataList.map(el => {
            return { value: el, label: el.name }
        })
        this.countryList = this.countries.map(el => {
            return { value: { name: el.countryNameEn, code: el.countryCode }, label: el.countryNameEn }
        })
        await this.getCustomerList();
    },
    methods: {
        async getCustomerList() {
            let config = {
                method: "GET",
                url: this.setURL,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    // this.userList = await response.data.data;
                    this.userList = await response.data.data.map(el => {
                        return { value: el, label: el.firstName + " " + el.lastName }
                    });
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getShippingAddress: async function (id) {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.getAddress + id,
                headers: {
                    "Authorization": authHeader()
                }
            };
            await this.$axios(config).then((response) => {
                this.$swal.hideLoading();
                if (response.data.statusCode == 200) {
                    this.shippingAddressData = response.data.data.shippingAddress;
                }
            }).catch(error => {
                this.$swal.hideLoading()
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        async setUserData(data) {
            await this.getShippingAddress(data.id);
            this.userName = data.firstName + ' ' + data.lastName;
            this.userEmail = data.email;
            this.shippingEmail = data.email;
            this.userMobile = data.mobile;
        },
        addProduct() {
            this.idx = this.idx + 1;
            this.addedProductList.push({ productId: "" });
            this.colorValue[this.idx] = '';
            this.sizeValue[this.idx] = '';
        },
        calculateWeight: function () {
            this.totalWeight = 0;
            for (let i = 0; i < this.selectedProduct.length; i++) {
                this.totalWeight = this.totalWeight + parseFloat(this.selectedProduct[i].weight) * parseFloat(this.selectedProduct[i].quantity);
            }
        },
        productData(data, index) {
            if (this.selectedProduct[index] != undefined) {
                this.selectedProduct[index] = data;
                this.priceList[index] = data.price
                data.quantity = 1
            } else {
                data.quantity = 1
                this.selectedProduct.push(data);
                this.priceList.push(data.price);
            }
            this.calculateTotal();
        },
        selectColor(index) {
            this.selectedProduct[index].chossedColor = this.colorValue[index];
        },
        selectSize(index) {
            this.selectedProduct[index].chossedSize = this.sizeValue[index];
        },
        increaseQuantity(index) {
            this.selectedProduct[index].quantity = this.selectedProduct[index].quantity + 1;
            this.calculateTotal();
        },
        decreaseQuantity(index) {
            (this.selectedProduct[index].quantity == 1) ? (this.selectedProduct[index].quantity = 1) : (this.selectedProduct[index].quantity = this.selectedProduct[index].quantity - 1);
            this.calculateTotal()
        },
        removeItem(index) {
            this.selectedProduct.splice(index, 1);
            this.addedProductList.splice(index, 1);
            this.productValue.splice(index, 1);
            this.colorValue.splice(index, 1);
            this.sizeValue.splice(index, 1);
            this.calculateTotal()
        },
        addDiscount: function () {
            let discountCheck = ''
            if (this.discount.slice(-1) == '%') {
                discountCheck = this.discount.slice(0, -1);
            }
            else {
                discountCheck = this.discount;
                this.total = this.subTotal;
            }
            if (isNaN(discountCheck)) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter valid discount amount or percentage"
                });
                this.discount = '';
                return;
            }
            if (this.discount && !isNaN(discountCheck)) {
                if (this.discount.includes("%")) {
                    let converted_discount = parseFloat(this.discount) / 100.0;
                    this.total = this.subTotal - this.subTotal * converted_discount;
                }
                else {
                    try {
                        let converted_discount = parseFloat(this.discount);
                        this.total = this.subTotal - converted_discount;
                    }
                    catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Please enter discount amount or percentage"
                        });
                    }
                }
            }
            else {
                this.total = this.subTotal;
            }

        },
        calculateTotal() {
            this.subTotal = 0;
            for (let el in this.selectedProduct) {
                if (this.selectedProduct[el].price && !isNaN(this.selectedProduct[el].price)) {
                    this.subTotal = this.subTotal + (this.selectedProduct[el].price ? this.selectedProduct[el].price * this.selectedProduct[el].quantity : this.selectedProduct[el].discountedPrice * this.selectedProduct[el].quantity);
                    this.total = this.subTotal;
                }
                else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter valid amount"
                    });
                    this.selectedProduct[el].price = this.priceList[el];
                    this.calculateTotal();
                }

            }
            if (this.discount) {
                this.addDiscount();
            }
            this.calculateWeight();
        },
        getServiceList: async function () {
            if (this.validation())
                await this.checkShipping();
        },
        getUpsServiceList: async function () {
            if (this.validation())
                await this.upsCheckShipping();
        },
        setShippingCost: function (price) {
            this.shippingCost = price;
        },
        checkShipping: async function () {
            this.serviceTypeList = [];
            this.shippingAddressData.country = this.shippingCountry.name;
            this.shippingAddressData.countryCode = this.shippingCountry.code;
            let data = {
                shippingAddress: this.shippingAddressData,
                weightValue: 50 //Math.ceil(this.totalWeight)
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.order.checkShippingURL,
                data: data,
                headers: {
                    "Authorization": authHeader()
                }
            };
            this.$swal.showLoading();
            await this.$axios(config).then((response) => {
                this.$swal.hideLoading();
                this.$swal.close();
                if (response.status == 200 && response.data.statusCode) {
                    if (response.data.statusCode == 400 || response.data.statusCode == 401 || response.data.statusCode == 403 || response.data.statusCode == 404 || response.data.statusCode == 500 || response.data.statusCode == 503) {
                        for (let i = 0; i < response.data.data.errors.length; i++) {
                            this.$swal.fire({
                                icon: "error",
                                text: response.data.data.errors[i].message
                            });
                        }
                    }
                }
                else if (response.status == 200) {
                    this.serviceTypeList = response.data;
                }

            }).catch(error => {
                this.$swal.hideLoading();
                this.$swal.close();
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        upsCheckShipping: async function () {
            this.serviceTypeList = [];
            let data = {
                shippingAddress: this.shippingAddressData,
                weightValue: this.totalWeight
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.order.upsCheckShippingURL,
                data: data,
                headers: {
                    "Authorization": authHeader()
                }
            };
            this.$swal.showLoading();
            await this.$axios(config).then((response) => {
                this.$swal.hideLoading();
                this.$swal.close();
                if (response.status == 200 && response.data.statusCode) {
                    if (response.data.statusCode == 400 || response.data.statusCode == 401 || response.data.statusCode == 403 || response.data.statusCode == 404 || response.data.statusCode == 500 || response.data.statusCode == 503) {
                        for (let i = 0; i < response.data.data.response.errors.length; i++) {
                            this.$swal.fire({
                                icon: "error",
                                text: response.data.data.response.errors[i].message
                            });
                        }
                    }
                }
                else if (response.status == 200) {
                    this.serviceTypeList = response.data;
                }

            }).catch(error => {
                this.$swal.hideLoading();
                this.$swal.close();
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        validation: function () {
            if (!this.userName) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide customer name.",
                });
                return false;
            } else if (!this.userEmail) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide customer email.",
                });
                return false;
            } else if (!this.userMobile) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide customer Mobile.",
                });
                return false;
            } else if (this.shippingAddressData.mobile.length < 9) {
                this.$swal.fire({
                    icon: "info",
                    text: "Mobile number must be at least 10 digit",
                });
                return false;
            } else if (!this.shippingAddressData.name) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide shipping name.",
                });
                return false;
            } else if (!this.shippingAddressData.email) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide shipping email.",
                });
                return false;
            } else if (!this.shippingAddressData.mobile) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide shipping contact no.",
                });
                return false;
            } else if (this.shippingAddressData.mobile.length < 9) {
                this.$swal.fire({
                    icon: "info",
                    text: "Mobile number must be at least 10 digit",
                });
                return false;
            } else if (!this.shippingAddressData.addressOne) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide shipping address1.",
                });
                return false;
            } else if (!this.shippingAddressData.city) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide shipping city.",
                });
                return false;
            } else if (!this.shippingAddressData.postCode) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide shipping post code.",
                });
                return false;
            } else {
                return true;
            }
        },
        async createOrder() {
            if (!this.shippingType) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select shipping type.",
                });
                return;
            }
            else if (!this.serviceType) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select service type.",
                });
                return;
            } else {
                this.$swal.showLoading();
                this.confirmedProduct = this.selectedProduct.map((el) => {
                    return { productId: el.id, quantity: el.quantity, variants: [el.chossedColor, el.chossedSize] };
                })
                let data = {
                    customerName: this.userName,
                    email: this.userEmail,
                    contactNo: this.userMobile,
                    shippingAddress: JSON.stringify({
                        name: this.shippingAddressData.name,
                        mobile: this.shippingAddressData.mobile,
                        email: this.shippingAddressData.email,
                        city: this.shippingAddressData.city,
                        postCode: this.shippingAddressData.postCode,
                        stateCode: this.shippingAddressData.stateCode,
                        country: this.shippingCountry.name,
                        countryCode: this.shippingCountry.code,
                        addressOne: this.shippingAddressData.addressOne,
                        addressTwo: this.shippingAddressData.addressTwo,
                    }),
                    shippingType: this.shippingType,
                    shippingCost: this.shippingCost,
                    serviceType: this.serviceType,
                    weightValue: this.totalWeight,
                    products: this.confirmedProduct,
                    remarks: this.remarks,
                    itemSubTotal: this.subTotal,
                    itemTotal: this.total
                }
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.order.createOrderURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.$axios(config).then((response) => {
                    this.$swal.hideLoading();
                    if (response.data.statusCode == 200) {
                        this.$swal.fire({
                            icon: "success",
                            text: response.data.message,
                        });
                        this.$router.push('/orders');
                    }
                }).catch(error => {
                    this.$swal.hideLoading()
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    },
    watch: {
        isExistingUser: function () {
            if (this.isExistingUser == false) {
                this.userDetails = ""
                this.userName = "";
                this.userEmail = "";
                this.userMobile = "";
                this.shippingName = "";
                this.shippingContactNo = "";
                this.shippingAddressData = {};
            }
        },
    }
}